import { amsClient } from 'src/service/ams';
import Config from 'src/service/config';

const state = () => ({
  links: [],
});

const getters = {
  links: (state) => state.links,
};

const mutations = {
  setNavLinks: (state, payload) => {
    state.links = payload;
  },
};

const actions = {
  getNavLinks: async ({ commit }) => {
    let items = [];

    try {
      const data = await amsClient.callAms(Config.get('amsEntryPoint'));

      if (data?.items?.length) {
        items = data.items.map((item) => {
          if (item.title === 'Store') {
            return {
              ...item,
              title: 'Stores',
              link: '/stores',
            };
          }

          return item;
        });
      }

      commit('setNavLinks', items)

      return items;
    } catch (error) {
      console.log('getNavLinks: ', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
