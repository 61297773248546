<template>
  <component
    :is="tag"
    :key="`trim-${text}`"
    v-resize-element="debouncedTrim"
    v-observe-visibility="{callback: visibilityChange, intersection: intersection, throttle: 300}"
    :title="text | stripHTML"
  >
    {{ trimedText | stripHTML }}
  </component>
</template>

<script>
  import { stripHTML } from '../../filters';
  import debounce from 'lodash/debounce';
  import { memoryStore } from 'vimmi-web-utils/esm/memoryStore';

  export default {
    props: {
      text: {
        type: String,
        required: true,
      },
      tag: {
        type: String,
        default: 'span',
      },
      rowNum: {
        type: Number,
        default: null,
      },
      nativeRow: {
        default: false,
        type: [Boolean],
      },
    },
    data() {
      return {
        trimedText: this.text,
        canvas: {},
        ctx: {},
        DEFAULT_WIDTH_ELEMENT: 200,
        cashe: {},
        isVisible: false,
        textMistake: 1,
        DEBOUNCE_TIME: 25,
        intersection: {
          threshold: 0,
        },
      };
    },
    computed: {
      debouncedTrim() {
        return debounce(this.trimTextBinded, this.DEBOUNCE_TIME, {
          leading: false,
          trailing: true,
        });
      },
    },

    watch: {
      text: {
        handler: function() {
          this.debouncedTrim();
        },
      },
    },

    created() {
      this.canvas = document.createElement('canvas');
      this.canvas.hidden = true;
      this.canvas.height = '1px';
      this.canvas.width = '1px';
      this.ctx = this.canvas.getContext('2d');
    },

    mounted() {
      // this.trimedText = this.trimText(this.$el, this.text);
      // this.$bus.$on('resizeWindow',  this.trimTextBinded);
    },

    methods: {
      trimTextBinded() {
        this.$nextTick(() => {
          this.trimedText = this.trimText(this.$el, this.text);
        });
      },
      visibilityChange(isVisible, entry) {
        this.isVisible = isVisible;
        if (isVisible) {
          this.debouncedTrim();
        }
      },
      getFont(styles) {
        let font = styles.font;
        if (!font) {
          font =
            styles.fontWeight +
            ' ' +
            styles.fontStyle +
            ' ' +
            styles.fontVariant +
            ' ' +
            styles.fontSize +
            '/' +
            styles.lineHeight +
            ' ' +
            styles.fontFamily;
        }
        return font;
      },

      getIntValue(value, defaultValue = null) {
        let val = parseInt(value);
        return !isNaN(val) ? val : defaultValue;
      },

      checkPositive(value, defaultReturn = 0) {
        return value > 0 ? value : defaultReturn;
      },

      calculateHeight(styles, defaultHeight = 0) {
        let maxHeight = this.getIntValue(styles.maxHeight);
        let height = this.getIntValue(styles.height);
        let ht = maxHeight > 0 ? maxHeight : height > 0 ? height : defaultHeight;

        let paddingTop =
          this.getIntValue(styles.paddingTop, 0) +
          this.getIntValue(styles.paddingBottom, 0);
        return this.checkPositive(ht - paddingTop, 0);
      },

      calculateWidth(styles, width) {
        let paddingLn =
          this.getIntValue(styles.paddingLeft, 0) +
          this.getIntValue(styles.paddingRight, 0);
        let wdth = Math.round(this.checkPositive(width - paddingLn));
        return wdth;
      },

      getNormalizeText(text) {
        let replaceRow = this.nativeRow ? ' <br/> ' : '';
        let txtRows = text.replace('\n', replaceRow);
        return txtRows;
      },

      getCasheDate(name) {
        return memoryStore.get(name) || null;

      },

      setCasheDate(nama, data) {
        memoryStore.set(name, data);
      },

      spaceIndexOf(text = '', start = 0, end = 0, last = false) {
        if (text && text.length > 0) {
          let endText = Math.min(text.length - 1, end);
          let txt = text.slice(start, end);
          let regexp = last ? /[\s|-]+(?!.*[\s|-])/gi : /[\s|-]+/i;
          regexp.lastIndex = start;
          let res = txt.search(regexp);
          return res;
        }
        return -1;
      },

      findStringByWidth(text, width) {
        if (text && text.length > 0) {
          let textWidth = this.getWordWidth(text);
          let index = Math.min(
            Math.floor((width / textWidth) * text.length),
            text.length,
          );
          let lastSpace =
            index !== text.length
              ? this.spaceIndexOf(text, 0, index + 1, true)
              : -1;
          if (lastSpace > 0) {
            let norm = Math.min(lastSpace + 1, text.length - 1);
            let res = text.slice(0, norm);
            if (this.getWordWidth(res) > width) {
              return this.findStringByWidth(res, width);
            }
            return res;
          }
          return text;
        } else {
          return text;
        }
      },

      transformByTextLen(text, rows, width) {
        let name = `W${width}_R${rows}_T${text}`;
        if (this.getCasheDate(name)) {
          return this.getCasheDate(name);
        }
        let txtRows = this.getNormalizeText(text);
        let endSymbolsWidth = this.getWordWidth(stripHTML('...'));
        let strings = [];
        let currentStrings = 0;
        let textLenght = txtRows.length;
        let rowsIndex = (width * rows) / this.getWordWidth(txtRows);
        if (rowsIndex < 1) {
          txtRows = txtRows.slice(0, Math.floor((rowsIndex + 0.1) * textLenght));
          textLenght = txtRows.length;
        }
        // console.group(name);
        for (
          let currentStrings = 0;
          currentStrings < rows && textLenght > 0;
          currentStrings++
        ) {
          let isLastRow = !(currentStrings + 1 < rows);
          let str = this.findStringByWidth(txtRows, width);
          let sliceIndex = Math.min(str.length, txtRows.length);

          // NOTE: if '\n' is first item ignore it
          if (this.nativeRow && str.indexOf('<br/>') > 0) {
            sliceIndex = str.indexOf('<br/>');
            str = str.slice(0, sliceIndex);
          }

          txtRows = txtRows.slice(sliceIndex);
          strings.push(str);

          if (isLastRow && txtRows.length > 0) {
            str = this.findStringByWidth(str, width - endSymbolsWidth);
            str += '&hellip;';
            strings.splice(currentStrings, 1, str);
          }
          textLenght = txtRows.length;
        }
        let res = strings.join('');
        this.setCasheDate(name, res);
        return res;
      },

      getWordWidth(val) {
        // this.ctx.font = this.getFont(styles);
        let metrics = this.ctx.measureText(val);
        return Math.round(metrics.width);
      },

      trimText(el, txt) {
        let text = stripHTML(txt); //.replace('\n', ' ').replace('\r', '');
        if (text) {
          if (this.isVisible) {
            let textMeasure = text.trim().replace(/\r?\n/gi, '');
            let styles = getComputedStyle(el);

            // get Symbol width
            this.ctx.font = this.getFont(styles);
            // let endMetrics = this.ctx.measureText('...');
            // let metrics = this.ctx.measureText(textMeasure);
            let currentWidth = this.getWordWidth(textMeasure); //metrics.width;
            let symbolPix = currentWidth / textMeasure.length;

            // Get Rows
            let htElem = this.calculateHeight(styles, el.clientHeight);
            let lineHeight = this.getIntValue(styles.lineHeight, 1);
            let rows = this.rowNum || Math.max(Math.floor(htElem / lineHeight), 1);
            // Get target Width
            let width = this.checkPositive(
              this.calculateWidth(styles, el.clientWidth),
              this.DEFAULT_WIDTH_ELEMENT,
            );
            let targetWidth = width * rows;
            return this.transformByTextLen(text, rows, width);
          } else {
            return this.nativeRow ? text.replace('\n', '<br/>') : text;
          }
        } else {
          return '';
        }
      },
    },
  };
</script>
