<template>
  <div class="links" :class="isOpen ? 'open' : null">
    <button
      type="button"
      class="nav-toggle btn mobile-visible"
      @click="toggleNav"
    >
      <i class="fas" :class="isOpen ? 'fa-times' : 'fa-bars'"/>
    </button>
    <button
      type="button"
      class="close-btn btn mobile-visible"
      @click="isOpen = false"
    >
      <i class="fas fa-times"/>
    </button>
    <div class="links-container">
      <div class="links-group mobile-visible">
        <a
          v-if="!isFullAuth"
          class="links-item"
          @click.stop="onLogin()"
        >
          {{ $t('HEADER.LOGIN') }}
          <img :src="require(`@/assets/icons/icon-login.svg`)" alt="">
          <i class="login-icon fas fa-chevron-right mobile-visible"/>
        </a>
        <div
          v-else
          class="links-item logout"
          @click="showMobileAuthActions"
        >
          {{ username }}
          <i class="fas fa-chevron-right"/>
        </div>
      </div>
      <hr class="mobile-visible wide">
      <div v-if="!$isStandaloneStore" class="links-group">
        <div class="links-group__title mobile-visible">
          Menu
        </div>
        <router-link
          v-for="item in items"
          :key="item.id"
          :class="item.slug === 'home' ? 'mobile-visible' : null"
          class="links-item"
          :to="item.link ? (item.itype === 'item_app' ? item.link.replace('get_item', 'web') : item.link) : '/'"
          @click.native="isOpen = false"
        >
          <img
            v-if="isShowIcon(item.slug)"
            :src="require(`@/assets/icons/icon-${item.slug}.svg`)"
            class="links-icons mobile-visible"
            :alt="item | contentLanguage('title')"
          >
          {{ item | contentLanguage('title') }}
        </router-link>
      </div>

      <template>
        <hr class="mobile-visible">
        <catalog/>
      </template>
    </div>
  </div>
</template>

<script>
  import { AuthVariableMixin } from 'src/plugins/mixin';
  import { amsClient } from 'src/service/ams';
  import Config from 'src/service/config';
  import Auth from 'src/service/authService';
  import Catalog from 'src/components/header/catalog/Catalog';

  export default {
    name: 'NavLinks',
    components: {
      Catalog,
    },
    mixins: [AuthVariableMixin],
    data() {
      return {
        isOpen: false,
        // items: [],
        user: Auth.get('user'),
      }
    },
    computed:{
      username() {
        return this.user?.name || this.user?.username;
      },
      items() {
        return this.$store.getters['navLinks/links'];
      },
    },
    watch:{
      isOpen(isOpen){
        this.$bus.$emit('ScrollPositionToggleStatus', isOpen)
      },
    },
    mounted() {
      amsClient.on('auth_state_change', () => this.user = Auth.get('user'));
    },
    created() {
      // this.$store.dispatch('navLinks/getNavLinks')

      this.$bus.$on('ToggleNavLinks', (data) => this.isOpen = data);
    },
    destroyed() {
      this.$bus.$off('ToggleNavLinks');
    },
    methods: {
      toggleNav() {
        this.isOpen = !this.isOpen;
      },
      onLogin() {
        this.isOpen = false;
        this.$emit('onLogin')
      },
      logout() {
        this.$emit('logout');
        this.$bus.$emit('ToggleNavLinks', false);
      },
      isShowIcon(slug) {
        return ['home', 'live', 'Black_Friday', 'stores_categories'].includes(slug);
      },
      showMobileAuthActions() {
        this.$bus.$emit('ToggleMobileAuthActions', true);
      },
    },
  }
</script>

<style lang="scss" scoped="true">
  @import 'NavLinks';
</style>
