<template>
  <div class="success-register-popup__container" @click.prevent.stop="closeModal">
    <div class="success-register-popup__body">
      <div class="success-register-popup__success-register-icon">
        <img :src="successImage">
      </div>
      <p class="success-register-popup__message">
        Thank you for registering. Please login using your credentials.
      </p>
      <div class="success-register-popup__actions">
        <button class="success-register-popup__button success-register-popup__button--close" @click="closeModal">
          Close
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'SuccessRegisterPopup',
    data() {
      return {
        successImage: require('src/assets/icons/icon-success.svg'),
      };
    },
    methods: {
      closeModal() {
        this.$emit('closeSuccessRegisterPopup');
      },
      returnToPrevRoute() {
        this.$router.back();
      },
    },
  };
</script>
<style lang="scss" scoped>
@import './success-register-popup.scss';
</style>
