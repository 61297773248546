import { Device } from 'vimmi-web-utils/cjs/device';

class DeviceService extends Device {
  constructor() {
    super(true);
  }


  /*
    - Chrome: latest version (Windows 7, Windows 10, Mac OS 10.14)
    - Firefox: latest version (Windows 7, Windows 10, Mac OS 10.14)
    - Safari: latest version (Mac OS 10.14)
    - Microsoft Edge: latest version (Windows 10)
    - Internet Explorer 11 (Windows 10)
  * */
  isPlatformSupports() {
    /// chrome < 70 version
    if (device.isChrome() && parseInt(device.getBrowserVersion()) >= 70) {
      return true;
    }
    /// Firefox < 67 version
    if (device.isFirefox() && parseInt(device.getBrowserVersion()) >= 67) {
      return true;
    }
    /// Edge < 75 version
    if (device.isEdge() && parseInt(device.getBrowserVersion()) > 75) {
      return true;
    }
    /// IE < 11 version
    if (device.isIE() && parseInt(device.getBrowserVersion()) > 11) {
      return true;
    }
    /// Opera < 57 version
    if (device.isOpera() && parseInt(device.getBrowserVersion()) > 57) {
      return true;
    }
    /// Safari < 12 version
    if (device.isSafari() && parseInt(device.getBrowserVersion()) >= 12) {
      return true;
    }

    return false
  }
}

const device = new DeviceService();
export default device
