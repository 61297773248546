<template>
  <div
    v-if="items.length"
    ref="liveContainer"
    class="container-fluid p-0 media-player"
  >
    <div v-if="maxAtt" class="overlay">
      <div class="overlay__content">
        <p class="overlay__content-message">
          This Live Event has reached its maximum attendance.<br>
          Please refresh to check live
        </p>
        <button class="overlay__content-button" @click="refreshPage()">
          Refresh
        </button>
      </div>
    </div>
    <div class="d-flex flex-wrap h-100">
      <div
        class="w-100 media-player-container h-100"
        :class="{'media-player-extended':extended, 'col-lg-8' : !isOne}"
      >
        <div v-if="isPrivate && !isAuthForPrivateEvent" class="private-event embed-responsive embed-responsive-16by9">
          <div class="private-event__form password-form">
            <p class="private-event__form-message">
              To access this event please enter the password
            </p>

            <div class="private-event__form-container">
              <div v-if="errorMessage" class="text-danger w-100 text-center">
                {{ errorMessage }}
              </div>

              <label for="password">Password</label>

              <div class="private-event__form-group">
                <input id="password" v-model="password" class="private-event__form-input" type="password"
                       name="password">

                <button class="private-event__form-button" :disabled="isLoading" @click="playPrivateEvent(password)">
                  Continue
                </button>

                <loader v-if="isLoading" />
              </div>
            </div>
          </div>
        </div>
        <media-video
          v-else
          class="media-player-video h-100"
          :shop-layouts="shopLayouts"
          :aspect-ratio="aspectRatio"
          :event-identifier="eventIdentifier"
          :autoplay="autoplay"
          :item="area"
          :live-item="item"
          :live-player="isLive"
          :itype="currentItem.itype"
          :is-update="isUpdate"
          :first-event-component="firstEventComponent"
          :vod="vod"
        />
      </div>
      <media-related
        v-if="!isOne && currentItem && currentItem.itype && !isLoading "
        class="col-lg-4 col-12 pl-3 pl-lg-0 media-player-related align-self-stretch"
        :video-items="items"
        :event-identifier="eventIdentifier"
        :current="currentItem"
        :not-reload-page="true"
      />
    </div>
  </div>
</template>

<script>
  import MediaVideo from '@/components/player/video/video';
  import MediaRelated from '@/components/player/media/media-related';
  import contentLanguage from '@/filters/contentLanguage.js';
  import stripHTML from '@/filters/stripHtml.js';
  import { AuthVariableMixin, FavoriteMixin } from '@/plugins/mixin';
  import ShareBtn from '@/components/share-btn/share-btn';
  import SubscriptionLabel from '@/components/subscription-label';
  import { amsClient } from '@/service/ams';
  import { memoryStore } from 'vimmi-web-utils/cjs/memoryStore';
  import { sessionStore } from 'vimmi-web-utils/esm/sessionStore';
  import rtm from '@/service/realTimeMessages';
  import Loader from '@/components/loader/loader';
  import { PM, SHOP_METHODS } from 'vimmi-web-utils/cjs/postMessage';

  export default {
    name: 'MediaPlayer',
    components: {
      MediaVideo,
      MediaRelated,
      ShareBtn,
      SubscriptionLabel,
      Loader,
    },
    mixins: [FavoriteMixin, AuthVariableMixin],
    props: {
      area: {
        type: Object,
        default: () => ({}),
      },
      item: {
        type: Object,
        default: () => ({}),
      },
      isUpdate: {
        type: Boolean,
        default: false,
      },
      aspectRatio: {
        type: String,
        default: null,
      },
      extended: {
        type: [String, Boolean],
        default: false,
      },
      notReloadPage: {
        type: [String, Boolean],
        default: true,
      },
      link: {
        type: String,
        default: null,
      },
      isLive: {
        type: Boolean,
        default: false,
      },
      shopLayouts: {
        type: [Object, String],
        default: null,
      },
      isOne: {
        type: Boolean,
        default: false,
      },
      floatingComponent: {
        type: Boolean,
        default: false,
      },
      firstEventComponent: {
        type: Boolean,
        default: false,
      },
      vod: {
        type: Boolean,
        default: true,
      },
      playerId: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        isAuthForPrivateEvent: false,
        isLoading: true,
        eventId: Math.ceil(Math.random() * 10000000),
        currentItem: {},
        currentData: {},
        items: Object.assign([], [this.area]),
        autoplay: true,
        offers: {},
        successPurchase: false,
        errorMessage: null,
        maxAtt: false,
        password: '',
      };
    },
    computed: {
      /** nend for mixin */
      eventIdentifier() {
        if (!!this.playerId) {
          return this.playerId;
        }
        return this.eventId;
      },
      isPrivate() {
        return this.currentItem.is_private;
      },
      mediaId() {
        return this.currentItem.id;
      },
      itype() {
        return this.currentItem.itype;
      },
      title() {
        return stripHTML(contentLanguage(this.currentItem, 'title'));
      },
      poster() {
        return this.currentItem.poster || this.currentItem.backdrop;
      },
      description() {
        return stripHTML(
          contentLanguage(this.currentItem, 'description') ||
          contentLanguage(this.currentItem, 'synopsis'),
        );
      },
      directors() {
        return stripHTML(
          contentLanguage(this.currentItem, 'directors'),
        );
      },
      canPlayPremiumContent() {
        return this.isFullAuth && this.currentItem && amsClient.isContentPremium(this.currentItem) && amsClient.canPlayPremium(this.currentItem);
      },
      // TODO: ft block-subscribe-btn
      isSubscribeBtn() {
        return this.isActiveFeature('block-subscribe-btn');
      },
      isShowBuyBtn() {
        return this.currentItem.not_free && !this.canPlayPremiumContent && !this.successPurchase;
      },
      currentCustomer() {
        return process.env.VUE_APP_CUSTOMER;
      },
      isMiniatureIframe() {
        return this.screenType === 'miniature';
      },
    },
    watch: {
      $route() {
        if (this.isPrivate && !this.isAuthForPrivateEvent) {
          this.$store.commit('dataAreasLive/setCurrentLive', null);
          this.$store.commit('player/SET_PLAYER_COORDS', null);
        }
      },
    },
    created() {
      if (!this.isOne) {
        this.getRelatedList();
      } else {
        this.getCurrentItem();
      }

      this.$bus.$on('hideBuyBtn', () => this.successPurchase = true);
    },
    mounted() {
      // this.isMaxAttendance(); WT-2200 - temporarily disabled
      // Vue.prototype.$floatingLiveRef = this.$refs.liveContainer;

      this.isLoading = !this.isPrivate;
      window.addEventListener('popstate', this.eventPopstateChange);
      memoryStore.set('main_player', this.eventIdentifier);

      if (this.vod) {
        memoryStore.set('vod-player-identifier', this.eventIdentifier);
      } else {
        memoryStore.set('live-player-identifier', this.eventIdentifier);
      }

      if (
        this.area.hasOwnProperty('genre_code') &&
        this.area.genre_code.length > 0
      ) {
        sessionStore.set('genre', this.area.genre_code[0]);
      } else {
        sessionStore.set('genre', 'none');
      }

      // this.autoplay = memoryStore.get('autoplay') || true;
      this.$bus.$on(
        this.eventIdentifier + '_playerReady',
        this.event_playerReady,
      );

      this.$bus.$on(
        this.eventIdentifier + '_changeCurrentVideo',
        this.event_changeCurrentVideo,
      );

      this.$bus.$on(
        this.eventIdentifier + '_playerNextPlaying',
        this.event_playerClickPrevNext,
      );

      this.$bus.$on(
        this.eventIdentifier + '_playerPrevPlaying',
        this.event_playerClickPrevNext,
      );

      this.$bus.$on(
        this.eventIdentifier + '_loadedMetadata',
        this.event_loadedMetadata,
      );

      this.$bus.$on('hideBuyBtn', () => this.successPurchase = true);
      this.$bus.$on('logout', () => this.successPurchase = false);

      if (this.floatingComponent && this.isMiniatureIframe) {
        PM.inst().target(this.eventIdentifier + '_video_player').send({
          type: SHOP_METHODS.SWITCH_MODE,
          data: {
            mode: 'f',
          },
        });
      }
    },
    destroyed() {
      window.removeEventListener('popstate', this.eventPopstateChange);

      this.$bus.$off(
        this.eventIdentifier + '_loadedMetadata',
        this.event_loadedMetadata,
      );
      this.$bus.$off(
        this.eventIdentifier + '_playerNextPlaying',
        this.event_playerClickPrevNext,
      );
      this.$bus.$off(
        this.eventIdentifier + '_playerPrevPlaying',
        this.event_playerClickPrevNext,
      );
      this.$bus.$off(
        this.eventIdentifier + '_changeCurrentVideo',
        this.event_changeCurrentVideo,
      );
      this.$bus.$off(
        this.eventIdentifier + '_playerReady',
        this.event_playerReady,
      );
      // this.$bus.$off(
      //   this.eventIdentifier + '_showNextAfterError',
      //   this.setNextAsCurrent
      // );
    },
    methods: {
      async playPrivateEvent(password) {
        this.isLoading = true;
        const url = `/shoprz/play_event/${this.mediaId}/?d=web&password=${password}`;
        try {
          const res = await amsClient.callAms(url, { cache: false });
          if (res.head.media) {
            this.currentItem.media = res.head.media;
            this.isAuthForPrivateEvent = true;
          }

          this.isLoading = false;
        } catch (e) {
          this.isLoading = false;
          this.errorMessage = e.message;
          setTimeout(() => this.errorMessage = null, 10000);
          console.error(e);
        }
      },
      refreshPage() {
        this.$router.go();
      },
      isMaxAttendance() {
        if (this.area.itype === 'item_epg_shop_event') {
          rtm.on('error', async (data) => {
            if (data.msg) {
              this.maxAtt = true;
            }
          });
        }
      },
      event_playerReady() {
        this.autoplay = this.canPlayPremiumContent || !this.currentItem?.not_free;
        this.$logger.log('MP', 'event_playerReady, autoplay:', this.autoplay, this.currentItem);
        this.$bus.$emit(this.eventIdentifier + '_playNowVideo', {
          item: this.currentItem,
          autoplay: this.autoplay,
          bigButton: true,
          clickTime: Date.now(),
        });
      },
      onChangedUserState(data) {
        if (this.clickBuyButton && data.type === 'logged' && !this.isGuest && this.isShowBuyBtn) {
          this.clickBuyButton = false;
          setTimeout(() => {
            this.$bus.$emit('showCouponPurchase', {
              itemId: this.currentItem.id,
              itemLink: this.currentItem.link,
            });
          }, 3000);
        }
      },

      event_changeCurrentVideo(options) {
        options = options || {};
        if (options.item.id === this.currentItem.id) {
          return;
        }
        this.$logger.log('MP', 'event_changeCurrentVideo', options.item.id);

        this.isLoading = true;
        this.currentItem = options.item;
        this.$set(this.currentItem, 'id', options.item.id);
        this.currentData = Object.assign({}, this.currentItem);
        this.$set(this.currentData, 'id', this.currentItem.id);
        this.isLoading = false;

        this.$bus.$emit(this.eventIdentifier + '_playNowVideo', {
          item: this.currentItem,
          autoplay: true,
          bigButton: true,
          clickTime: Date.now(),
          ...options,
        });

        if (options.scrollToView) {
          this.$bus.$emit('scrollVideoToView', true);
        }
      },
      event_loadedMetadata() {
        this.loadNextPrevItems();
      },
      event_playerClickPrevNext(asset) {
        this.nextLoaded = true;
        this.isLoading = true;
        let itemId = asset.media_id || asset.item.id;/* && options.extraData && options.extraData.item_id ? options.extraData.item_id :
          (options.id || null);*/
        this.$logger.info('MP', 'event_playerClickPrevNext', asset, itemId);
        // let data = response.data;
        // if (!data.error) {
        this.currentItem = asset.item;
        this.$set(this.currentItem, 'id', this.currentItem.id);
        this.currentData = Object.assign({}, this.currentItem);
        this.$set(this.currentData, 'id', this.currentItem.id);
        this.isLoading = false;
      },

      getCurrentItem() {
        if (this.items.length && !this.isOne) {
          this.currentItem = Object.assign({}, this.items[0]);
          this.$set(this.currentItem, 'id', this.items[0].id);
          this.currentData = Object.assign({}, this.currentItem);
          this.$set(this.currentData, 'id', this.currentItem.id);
          this.isLoading = false;
        } else {
          this.currentItem = Object.assign({}, this.area);
          this.$set(this.currentItem, 'id', this.area.id);
          this.currentData = Object.assign({}, this.currentItem);
          this.$set(this.currentData, 'id', this.currentItem.id);
          this.isLoading = false;
        }
      },

      loadNextPrevItems() {
        /**
         * only for episodes
         * */
        if (this.currentItem.itype === 'item_mov_episode') {
          // this.$bus.$emit(this.eventIdentifier + '_loadPrevNextVideo', {
          //   //                  item: opt.item,
          //   //                  play_type: opt.play_type,
          //   //                  [opt.play_type]: opt[opt.play_type],
          //   isNext: true,
          //   bigButton: true
          //   // ...opt,
          // });
          this.getNextItem()
            .then(opt => {
              if (opt) {
                this.$bus.$emit(this.eventIdentifier + '_loadPrevNextVideo', {
                  //                  item: opt.item,
                  //                  play_type: opt.play_type,
                  //                  [opt.play_type]: opt[opt.play_type],
                  isNext: true,
                  bigButton: true,
                  ...opt,
                });
              } else {
                this.$bus.$emit(this.eventIdentifier + '_actionOnButton', {
                  name: 'hideNext',
                });
              }
            })
            .catch(error => {
              this.$bus.$emit(this.eventIdentifier + '_actionOnButton', {
                name: 'hideNext',
              });
            });
        }
      },
      getNextItem() {
        let url = `/playnext/${this.currentItem.id}/?nomedia`;
        return amsClient.callAms(url, { cache: false })
          .then(data => {
            if (data) {
              return {
                item: data.head,
              };
            }
            return null;
          });
      },

      getPrevItem() {
        return this.items[this.items.length - 1];
      },

      changeItemsList(posCurrent) {
        let previousItems = this.items.splice(0, posCurrent);
        this.items = this.items.concat(previousItems);
      },

      getRelatedList(id) {
        if (!id && this.link) {
          amsClient.callAms(this.link, { cache: false })
            .then(data => {
              this.items = Object.assign([], [...data.items]);
              this.getCurrentItem();
            });
        } else {
          amsClient.callAms(`/related/${id}/`, { cache: false })
            .then(data => {
              this.items = Object.assign([], data.items);
              this.getCurrentItem();
              // Play Now
              this.$bus.$emit(this.eventIdentifier + '_playNowVideo', {
                item: this.currentItem,
                autoplay: true,
                bigButton: true,
                clickTime: Date.now(),
              });
            });
        }
      },
      eventPopstateChange() {
        this.$nextTick(() => {
          let query = this.$router.currentRoute.query.video_id;
          if (query) {
            let item = this.items.find(el => {
              return el.link === query;
            });
            if (item) {
              this.event_changeCurrentVideo({
                item: item,
                isPlayedItem: true,
                isEndedVideo: true,
              });
            }
          }
        });
      },
      showCouponPurchase() {
        if (this.isFullAuth) {
          this.$bus.$emit('toggleAuthPopup');

          this.$nextTick(() => {
            this.$bus.$emit('showCouponPurchase', {
              itemId: this.currentItem.id,
              itemLink: this.currentItem.link,
            });
          });
        } else {
          this.clickBuyButton = true;
          this.$bus.$emit('toggleAuthPopup', {
            component: 'login',
            force: true,
            formId: 'fromPlayer',
            item_id: this.currentItem.id,
          });
        }
      },
    },
  };
</script>

<style lang="scss">
  @import './media-player';
</style>
