<template>
  <!-- Muss be only one in Page ??? -->
  <div
    ref="eventPlayer"
    class="scroll-to-heading event-player"
  >
    <div
      ref="videoPlayer"
      class="video-player embed-responsive"
      @click="simulateButtonsClick"
    >
      <div
        v-if="!playerStatus"
        class="video-player-image embed-responsive-item"
      >
        <i class="fas fa-spinner-third fa-spin" />
      </div>

      <iframe
        v-if="videoUrl"
        id="video_player"
        :style="{ opacity: !playerStatus ? '0' : '1', position: fullscreenEmulate ? 'fixed' : null }"
        class="video-player-component embed-responsive-item"
        :class="{'not-clickable': isNotClickable && isProductVod}"
        width="100%"
        height="100%"
        :src="videoUrl"
        :name="eventIdentifier + '_video_player'"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        allow="autoplay; encrypted-media"
        lazyload="off"
        @click="showRestrictionMessage"
      />
    </div>
  </div>
</template>

<script>
  import { normalizeImg } from 'src/filters';
  import { floorTo, formatMediaUrl, truncateSymbolsToNumber } from 'src/utils';
  import Auth from 'src/service/authService';
  import { localStore } from 'vimmi-web-utils/esm/localStore';
  import Config from 'src/service/config';
  import { normalizeLang } from 'vimmi-web-utils/cjs/normalizeLang';
  import { normDomain } from 'vimmi-web-utils/cjs/normDomain';
  import { AuthVariableMixin } from 'src/plugins/mixin';
  import { amsClient } from '@/service/ams';
  import { PLAYER_EVENTS, PLAYER_METHODS, PM, SHOP_EVENTS, SHOP_METHODS } from 'vimmi-web-utils/cjs/postMessage';
  import { EmbeddedMode } from './embedded-mode';
  import PopupService from '@/service/popup';
  import { mapGetters } from 'vuex';
  import { deepMerge } from 'vimmi-web-utils/cjs/deepMerge';
  import between from 'src/utils/between';
  import Loader from '@/components/loader/loader';
  import { $stats } from 'src/plugins/analytics/vue-analytics-plugin';

  let cmp = null;

  export default {
    name: 'MediaPlayerVideoMP',
    components: {
      Loader,
    },
    mixins: [AuthVariableMixin, EmbeddedMode],
    props: {
      item: {
        type: Object,
        default: () => ({}),
      },
      vHeight: {
        type: String,
        default: null,
      },
      autoplay: {
        type: Boolean,
        default: null,
      },
      eventIdentifier: {
        type: Number,
        default: null,
      },
      shopLayouts: {
        type: [Object, String],
        default: null,
      },
      firstEventComponent: {
        type: Boolean,
        default: false,
      },
      tabs: {
        type: Array,
        default: () => ([]),
      },
      vod: {
        type: Boolean,
        default: false,
      },
      currentVideoItem: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        // Auth user and restriction
        isRestricted: null,
        clickOnMedia: Date.now(),
        // Item values
        prevOpt: {},
        currentItem: {},
        videoUrl: null,
        tempPoster: null,
        PMSubscribes: [],
        // Analytics
        iid: 0,
        prevId: 0,
        isLive: null,
        percentage: null,
        nextPercentage: 0,
        fullscreenEmulate: false,
        duration: null,
        position: 0,
        stream_time: 0,
        previewStop: false,
        isPriorityShowRegisterMess: !!localStore.get('afterRegister'),
        was5minMess: false,
        isAdsPlaying: false,
        isVideoPlaying: false,
        isVideoChangedDuringADS: false,
        // Player
        calcHeight: '100%',
        playerStatus: null, // 1 -init else not defined 0 - not init
        TagByIsWatchedVideoList: 'watchlist',
        TIMEOUT_FOR_NEW_VIDEO_AFTER_ERROR: 5 * 1000,
        idTimeoutNewVideoAtrerError: 0,
        isBeforeDestroy: false,
        isNotClickable: !!this.item.playerOptions,
      };
    },
    computed: {
      ...mapGetters({
        getVideosList: 'wishlist/getVideosList',
        getPlayTemplate: 'chachedPreviewMedia/getPlayTemplate',
        setTemplate: 'chachedPreviewMedia/setPlayTemplate',
      }),
      isInWishVideosList() {
        return Object
          .keys(this.getVideosList)
          .includes(this.item.id);
      },
      isVod() {
        return this.item?.itype?.includes('vod');
      },
      isMiniatureIframe() {
        return this.screenType === 'miniature';
      },
      isLiveFloating() {
        return this.$store.getters['popup/isLiveFloating'];
      },
      isProductVod() {
        return !!this.item.playerOptions;
      },
      playerCoords() {
        return this.$store.getters['player/playerCoords'];
      },
      isVodItem() {
        return this.$store.getters['player/isVodItem'];
      },
    },
    created() {
      cmp = this;
      this.$bus.$off('VideoPlayerPlayAction', this.eventPlayerMethods);
      this.$bus.$on('VideoPlayerPlayAction', (method) => {
        this.eventPlayerMethods(method);
      });

      this.$bus.$on('setSound', (method) => {
        this.send(method);
      });

      this.$bus.$on(this.eventIdentifier + '_pauseVideo', this.event_pauseVideo);
      this.$bus.$on(this.eventIdentifier + '_playVideo', this.event_playVideo);
      this.$bus.$on(
        this.eventIdentifier + '_playNowVideo',
        this.event_playNowVideo,
      );
      this.$bus.$on(
        this.eventIdentifier + '_loadPrevNextVideo',
        this.event_loadPrevNextVideo,
      );
      this.$bus.$on(
        this.eventIdentifier + '_enableControls',
        this.event_enableControls,
      );
      this.$bus.$on(
        this.eventIdentifier + '_disableControls',
        this.event_disableControls,
      );
      this.$bus.$on(
        this.eventIdentifier + '_setComponentPoster',
        this.event_setComponentPoster,
      );
      this.$bus.$on(
        this.eventIdentifier + '_actionOnButton',
        this.event_actionOnButton,
      );
      if (this.isProductVod) {
        this.$bus.$on(
          this.item.eventIdentifier + '_changeAudioMode',
          this.changeAudioMode,
        );
      }
      this.$bus.$on('pauseLive', this.pauseLive);
      this.$bus.$on('scrollVideoToView', this.scrollToView);
      this.$bus.$on('amsBeforeLogout', this.amsBeforeLogout);
      this.$bus.$on('beforeTerminated', this.beforeTerminated);
      this.$bus.$on('amsBeforeLogged', this.amsBeforeLogged);

    },
    mounted() {
      Config.loaded(async () => {
        if (!_isPreviewMode()) {
          this.calculateHeight();
          let initPlayerOption = {
            initEmpty: true,
            debug: true,
            font_size: '14px',
            env: Config.get('env'),
            lang: this.$i18n.locale,
          };
          setTimeout(() => {
            this.calculateHeight();
          }, 100);
          setTimeout(() => {
            this.calculateHeight();
          }, 500);

          if (Config.get('debug') || location.href.indexOf('debug=true') !== -1) {
            initPlayerOption.debug = true;
          }

          if (this.shopLayouts) {
            initPlayerOption.mode = this.isProductVod && this.isNotClickable ? this.item.playerOptions.mode : 'l';
            initPlayerOption.appId = this.shopLayouts.id;
            initPlayerOption.oId = this.shopLayouts.slug;
            initPlayerOption.dm = location.hostname;
            initPlayerOption.pg = location.href.replace(location.origin, '');
          }

          let playerUrl = Config.get('player.url');
          let options = Object.assign(
            Config.get('player.options'),
            {
              guest: Auth.get('user')?.guest ? 1 : 0,
              ident: this.eventIdentifier,
            },
            initPlayerOption,
          );

          this.videoUrl = this.formatMediaUrl(playerUrl, options);

          this.$nextTick(() => {
            this.handlePMEvents();
          });

          if (!this.vHeight) {
            this.$bus.$on('resizeWindow', this.calculateHeight);
          }

          this.$bus.$emit(this.eventIdentifier + '_videoComponentReady', {});

          this.TIMEOUT_FOR_NEW_VIDEO_AFTER_ERROR = Config.get(
            'player.timeoutChangingItems',
          );
        } else {
          this.$bus.$emit(this.eventIdentifier + '_videoComponentReady', {});
        }

        this.$gtag('event', 'view_vod', {
          store_name: this.item.creator.name,
          vod_media: this.isVodItem?.event?.media,
          vod_image: this.item.backdrop,
          vod_name: this.item.title,
        });
      });

      localStore.delete('afterRegister');

      if (this.item.itype !== 'item_epg_shop_event') {
        this.$bus.$emit('pauseLive');
      }
    },

    beforeDestroy() {
      PM.inst().off(this.PMSubscribes);
      this.isBeforeDestroy = true;
      if (!this.currentOptions?.preview) {
        this.setWatchedVideo();
        this.setResumeWatch();
      }


      /** analytics */
      this.skipNextAfterError();
      if (this.stream_time > 1) {
        const options = this.$stats.formatEventDate(this.currentItem);
        $stats.send('watch', { ...options });
        const eventData = this.$stats.collect(this.currentItem.id, 'heartbeat', 'ended', { stream_time: this.stream_time });
        if (eventData) {
          const options = this.$stats.formatEventDate(this.item);
          this.$stats.send('heartbeat', { ...options, ...eventData });
        }
        this.$stats.clearCollectedData(this.currentItem.id, 'heartbeat');
      }
      /** analytics end */

      this.setDailyPreview(true);

      this.$bus.$off('resizeWindow', this.calculateHeight);

      this.$bus.$off(
        this.eventIdentifier + '_playNowVideo',
        this.event_playNowVideo,
      );
      this.$bus.$off(
        this.eventIdentifier + '_actionOnButton',
        this.event_actionOnButton,
      );
      this.$bus.$off(this.eventIdentifier + '_playVideo', this.event_playVideo);
      this.$bus.$off(this.eventIdentifier + '_pauseVideo', this.event_pauseVideo);
      this.$bus.$off(
        this.eventIdentifier + '_loadPrevNextVideo',
        this.event_loadPrevNextVideo,
      );
      this.$bus.$off(
        this.eventIdentifier + '_disableControls',
        this.event_disableControls,
      );
      this.$bus.$off(
        this.eventIdentifier + '_enableControls',
        this.event_enableControls,
      );
      this.$bus.$off(
        this.eventIdentifier + '_setComponentPoster',
        this.event_setComponentPoster,
      );
      if (this.isProductVod) {
        this.$bus.$off(
          this.item.eventIdentifier + '_changeAudioMode',
          this.changeAudioMode,
        );
      }
      this.$bus.$off('pauseLive', this.pauseLive);
      this.$bus.$off('scrollVideoToView', this.scrollToView);
      this.$bus.$off('amsBeforeLogout', this.amsBeforeLogout);
      this.$bus.$off('amsBeforeLogged', this.amsBeforeLogged);
      this.$bus.$off('beforeTerminated', this.beforeTerminated);
    },

    methods: {
      normalizeImg,
      startUserEventPlay(event) {
        this.isVideoPlaying = true;
        this.$logger && this.$logger.info('MPV', 'play', event);
        // this.sendProductOfferPlayedOnEvent();
        this.$bus.$emit(this.eventIdentifier + '_playerPlay', event);
        this.$bus.$emit('VideoPlayerPlay', this.currentItem);
        this.skipNextAfterError();
        this.setWatchedVideo();
        this.playerStatus = 1;
      },
      startPlayerVideo(event) {
        this.startUserEventPlay(event);
        this.$store.commit('dataAreasLive/setPlayingLive', false);

        this.$store.commit('player/SET_FIRST_LIVE_PLAING', this.firstEventComponent);

        if (!this.vod) {
          if (this.isMiniatureIframe) {
            this.$bus.$emit('closeMiniVod');
            this.$store.commit('dataAreasLive/setPlayingLive', true);
            this.$bus.$emit('setMiniplayer');
            PopupService.setFloatingMode('live');
            this.$bus.$emit('setLiveFLoating');

            if (this.playerCoords) {
              const livePlayers = document.querySelectorAll('.channel-event-video--wrapper');

              livePlayers.forEach(item => {
                item.setAttribute('style', `top: ${this.playerCoords.y}px!important; left: ${this.playerCoords.x}px!important;`);
              });
            }

            return;
          }

          this.$store.commit('dataAreasLive/setPlayingLive', true);
          this.$bus.$emit('handleCloseFloatPlayer');
          this.$bus.$emit('closeMiniVod');
        }
      },
      changeAudioMode() {
        this.send(SHOP_METHODS.TOGGLE_MUTE);
      },
      closeVideo() {
        this.$bus.$emit('closeProductVideo');
      },
      simulateButtonsClick(event) {
        if (this.isProductVod) {
          const player_coordinates = this.$refs.videoPlayer.getBoundingClientRect();
          const areCloseButtonCoordinates = between(event.clientX, player_coordinates.right - 45, player_coordinates.right - 15) && between(event.clientY, player_coordinates.top + 15, player_coordinates.top + 45);
          const areChangeModeButtonCoordinates = between(event.clientX, player_coordinates.left + 15, player_coordinates.left + 45) && between(event.clientY, player_coordinates.top + 15, player_coordinates.top + 45);

          if (areChangeModeButtonCoordinates) {
            this.closeVideo();
            this.$bus.$emit('closeProductPopup');
            this.$bus.$emit('closeScheduledDialog');
            if (this.item.page === this.$route.fullPath) {
              this.$bus.$emit('openVodPopup', { ...this.item, playerOptions: false });
            } else {
              this.$router.push(this.item.page);
            }
          }

          if (areCloseButtonCoordinates) {
            this.closeVideo();
          } else {
            this.changeAudioMode();
          }
        }
      },

      pauseLive() {
        if (this.item.itype === 'item_epg_shop_event') {
          this.send(PLAYER_METHODS.PAUSE);
        }
      },
      // NOTE: imported functions
      truncateSymbolsToNumber,
      floorTo,
      formatMediaUrl,

      amsBeforeLogout() {
        if (this.isFullAuth) {
          this.previewStop = false;
          // Вызывается только в случае, если делаем логаут для обычного юзера
          this.setDailyPreview();
        }
      },
      amsBeforeLogged() {
        if (this.isGuest) {
          this.previewStop = false;
          // Вызывается только в случае логина обычного пользователя, если до этого был гость
          this.setDailyPreview();
        }
      },
      beforeTerminated() {
        cmp.setDailyPreview(true);
      },
      setDailyPreview(terminated = false) {
        if (
          !this.previewStop &&
          this.currentOptions?.preview &&
          this.stream_time
        ) {
          this.previewStop = true;
          amsClient.stock.setDailyPreviewTime(
            parseInt(this.stream_time + (this.currentOptions.preview_info.consumed_time || 0)),
            this.currentItem.id,
            parseInt(this.stream_time),
            'item',
            terminated,
          );
        }
        this.stream_time = 0;
      },
      onChangedUserState(data = {}) {
        // this.$logger && this.$logger.log('onChangedUserState', data);

        this.updatePlayerConf();

        let isWasRestricted = this.isRestricted;
        this.isVideoRestricted(this.currentItem).then((restriction) => {
          this.isRestricted = restriction;
          if (data.type === 'logged') {
            this.prevOpt.autoplay =
              this.stream_time > 1 && this.isVideoPlaying && !this.isRestricted;
            if (this.position) {
              this.prevOpt.startPosition =
                isWasRestricted && !this.isRestricted ? 0 : this.position;
            }

            this.event_PlayNowPart2(this.prevOpt);
            if (this.isRestricted) {
            } else if (isWasRestricted) {
              this.$bus.$emit(this.eventIdentifier + '_togglePlayerLayout', {
                value: this.prevOpt.autoplay,
                arrows: this.prevOpt.autoplay,
              });
              this.hideRestrictionMessage();
              this.skipNextAfterError();
            }
          } else if (
            data.type === 'logout' &&
            !this.isActiveFeature('guest-users')
          ) {
            if (this.isRestricted) {
              this.prevOpt.autoplay = this.stream_time > 1 && this.isVideoPlaying;
              this.prevOpt.startPosition = 0;
              this.event_PlayNowPart2(this.prevOpt);
              this.$bus.$emit(this.eventIdentifier + '_togglePlayerLayout', {
                value: true,
                arrows: false,
              });
            } else {
              this.hideRestrictionMessage();
              this.skipNextAfterError();
            }
          } else if (data.type === 'logout') {
            this.position = 0;
          }
        });
      },
      async onSync(data) {
        if (data && data.afterLogin) {
          return;
        }

        this.updatePlayerConf();

        let restriction = await this.isVideoRestricted(this.currentItem);
        let isWasRestricted = this.isRestricted;
        this.isRestricted = restriction;

        if (restriction) {
          this.setRestrictionState({ restricted: this.isRestricted });
          this.showRestrictionMessage();
        } else if (isWasRestricted) {
          //
          if (this.position) {
            this.prevOpt.startPosition = !this.isRestricted ? 0 : this.position;
          }

          this.prevOpt.autoplay = this.stream_time > 1 || this.isVideoPlaying;
          // if (this.position) {
          // this.prevOpt.startPosition = 0;
          // }
          this.event_PlayNowPart2(this.prevOpt);

          this.$bus.$emit(this.eventIdentifier + '_togglePlayerLayout', {
            value: this.prevOpt.autoplay,
            arrows: this.prevOpt.autoplay,
          });

          this.hideRestrictionMessage();
          this.skipNextAfterError();
        }
      },

      async updatePlayerConf() {
        /* this.$logger && this.$logger.log('[updatePlayerConf]', {
          user: Auth.get('user'),
          creator: this.currentItem.creator,
        }); */

        let analytics = {};

        if (this.isActiveFeature('buh-analytics')) {
          analytics.buh = amsClient.get('buh');
        }

        const chatConfig = amsClient.get('conf.chat');
        let chat = null;

        if (this.item.chat_id) {
          chat = {
            chat_id: this.item.chat_id,
            chat_url: this.item.chat_url,
            readonly: this.item.is_chat_enabled === undefined || this.item.is_chat_enabled === null
              ? false
              : !this.item.is_chat_enabled,
          };
        } else if (this.item.itype === 'item_mov_vod_shoprz') {
          const { head } = await amsClient.callAms(`/get_item/${this.item.id}/`);

          if (!head.event || !head.event?.chat_id) {
            return;
          }

          chat = {
            chat_id: head.event.chat_id,
            chat_url: head.event.chat_url,
            readonly: head.is_chat_enabled === undefined || head.is_chat_enabled === null
              ? false
              : !head.is_chat_enabled,
          };
        }

        this.send(PLAYER_METHODS.CONFIGURATION, {
          data: {
            portalUser: Auth.get('user'),
            isPortalUserAuth: this.isFullAuth,
            item: this.item,
            isWishList: this.isInWishVideosList,
            conf: {
              features: amsClient.get('conf.shoprz_features') || {},
              chatUrl: chatConfig?.chat_backend_url ? normDomain(chatConfig.chat_backend_url) : null,
              portalLink: amsClient.get('conf.portal.portal_link'),
            },
            chat: {
              ...chat,
              enabled: true,
            },
            creator: this.item?.creator || null,
            portal: {
              /** for player module, start*/
              public_app_key: Config.get('auth.ams.public_app_key'),
              private_id: Auth.get('user.privateId'),
              concurrency: Auth.get('concurrency'),
              'Device-Info': amsClient.getAMSDeviceInfo(),
              // browser: Device.info.browser.name,
              // os_version: Device.info.os.version,
              /** for player module, end*/

              app_id: Config.get('app_id'),
              app_info: Config.get('app_info'),
              app_version: Config.get('version'),
              user_lang: normalizeLang(Config.get('activeLang'), 'iso-2'),
              language: Config.get('activeLang'),
              user_id: Auth.get('user.id'),
              // temp_user_id: Auth.get('extraData.temp_id'),
              classification:
                (amsClient.get('user.classes') || []).join(',') || 'non-ais',
              device_id: Auth.get('authInfo.device_id'),
              device_key: Config.get('device_key'),
              // buhDeviceId: this.$stats.buhDeviceIdPart,
              customer: process.env.VUE_APP_CUSTOMER,
              sid: Auth.get('user.sid'),
              restrictedDevices: Auth.getDeviceRestriction(),
              preferred_audio_lang:
                localStore.get('preferred_audio_lang') || this.$i18n.locale,
              server: location.host,
              metadata: Config.get('player.metadata'),
              /// player params
            },
            player_conf: {
              vimmi_copyright: false,
              quality_selector: { reverse: true },
            },
            analytics: analytics,
          },
        });
      },
      handlePMEvents() {

        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(SHOP_METHODS.TOGGLE_FULLSCREEN, (data) => {
          this.fullscreenEmulate = data.emulate && data.state;
          this.$bus.$emit('togglePlayerFullscreen', data);
        }));
        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_EVENTS.READY, () => {
          Config.loaded(async () => {
            this.updatePlayerConf();

            this.playerStatus = 1;
            this.$bus.$emit(this.eventIdentifier + '_playerReady', {});
            this.$bus.$emit('resizeWindow');

            let chat = null;

            if (this.currentItem.chat_id) {
              chat = {
                chat_id: this.currentItem.chat_id,
                chat_url: this.currentItem.chat_url,
                readonly: this.currentItem.is_chat_enabled === undefined || this.currentItem.is_chat_enabled === null
                  ? false
                  : !this.currentItem.is_chat_enabled,
              };
            } else if (this.currentItem.itype === 'item_mov_vod_shoprz') {
              const { head } = await amsClient.callAms(`/get_item/${this.currentItem.id}/`);

              if (!head.event || !head.event?.chat_id) {
                return;
              }

              chat = {
                chat_id: head.event.chat_id,
                chat_url: head.event.chat_url,
                readonly: head.is_chat_enabled === undefined || head.is_chat_enabled === null
                  ? false
                  : !head.is_chat_enabled,
              };
            }

            this.$stats.collect(this.currentItem.id, 'heartbeat', 'ready', { stream_time: 0 });

            if (!chat) {
              return;
            }

            this.send('PLAYER_INIT_CHAT', {
              data: {
                chat: {
                  ...chat,
                  enabled: true,
                },
              },
            });
          });
        }));

        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(SHOP_EVENTS.APP_STATS, (data) => {
          if (data.event) {
            $stats.send(data.event, {
              ...data,
              channel_id: this.currentItem?.creator?.id,
              store_id: 'verb',
            });
          }
        }));

        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(SHOP_METHODS.OPEN_AUTH_POPUP, (data) => {
          this.$bus.$emit('toggleAuthPopup', {
            component: 'login',
            message: 'In order to write in chat, please log in',
            force: true,
          });
        }));

        // loadedMetadata Player Start
        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_EVENTS.LOADEDMETADATA, (event) => {
          if (!this.isRestricted) {
            // this.$logger && this.$logger.info('MPV', 'loadedMetadata', event);
            this.$bus.$emit(this.eventIdentifier + '_loadedMetadata', {});
            this.skipNextAfterError();
          }

          this.playerStatus = 1;
        }));

        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_METHODS.FORCE_LOGIN, (event) => {
          this.$bus.$emit('toggleAuthPopup', {
            force: true,
            component: 'login',
            formId: 'fromPlayer',
          });
        }));

        // Play
        // PM.inst().off(PLAYER_EVENTS.USER_PLAY);
        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_EVENTS.USER_PLAY, (event) => {
          this.startPlayerVideo(event);
          if (event.userAction) {
            // $stats.send('start', {
            //   media_id: this.currentItem.id,
            //   product_id: null, // todo:
            //   channel_id: this.currentItem.creator.id,
            //   media_type: this.currentItem.isLive ? 'live' : 'vod',
            // });
          }
        }));

        // Resume
        // PM.inst().off(PLAYER_EVENTS.USER_RESUME);
        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_EVENTS.USER_RESUME, (event) => {
          this.isVideoPlaying = true;
          this.$bus.$emit(this.eventIdentifier + '_playerResume', event);
          this.skipNextAfterError();
          this.setWatchedVideo();
          this.playerStatus = 1;
          this.duration = event.duration;
          this.position = event.position;
          if (event.userAction) {
            this.$stats.collect(this.currentItem.id, 'watch', 'resume', event);
          }
          this.startPlayerVideo(event);
        }));

        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_EVENTS.TIME_UPDATE, (event) => {
          if (this.isProductVod) {
            if (event.position >= this.item.playerOptions.endPosition || event.position >= event.duration) {
              this.$bus.$emit(this.item.eventIdentifier + '_playerEnded', {});
            }
          }
          this.duration = event.duration;
          this.position = event.position;
          this.stream_time = event.stream_time;

          this.$stats.collect(this.currentItem.id, 'watch', 'timeupdate', event);

          const eventData = this.$stats.collect(this.currentItem.id, 'heartbeat', 'timeupdate', event);
          if (eventData) {
            const options = this.$stats.formatEventDate(this.item);
            this.$stats.send('heartbeat', { ...options, ...eventData });
          }
        }));

        // Preview finished
        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_METHODS.PREVIEW_FINISH, (event) => {
          // this.$logger && this.$logger.info('MPV', 'previewFinish', event, this.isFullAuth);

          this.setDailyPreview();
          if (event.triggerMessage !== undefined && !event.triggerMessage) {
            return;
          }

          if (!this.isFullAuth) {
            this.$bus.$emit('toggleAuthPopup', {
              component: 'login',
              force: true,
              formId: 'fromPlayer',
            });
          } else {
            this.$bus.$emit('showCouponPurchase', {
              itemId: this.currentItem.id,
            });
          }
        }));

        // Preview finished (when the user stops to watching the preview on some position)
        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_METHODS.PREVIEW_STOP, (event) => {
          // this.$logger && this.$logger.info('MPV', 'previewStop', event);
          // this.previewStop = true;
          /** todo: need to send only for quest users or non_free items*/
          this.setDailyPreview();

          // if (!this.isAuth) {
          //   this.$bus.$emit('toggleAuthPopup', {
          //     login: true,
          //     center: true,
          //     trigger: false,
          //     formId: 'fromPlayer'
          //   });
          // } else {
          //   this.$bus.$emit('showCouponPurchase', {
          //     itemId: this.currentItem.id
          //   });
          // }
        }));

        // Pause
        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_EVENTS.USER_PAUSE, (event) => {
          this.isVideoPlaying = false;
          this.$bus.$emit(this.eventIdentifier + '_playerPause', event);
          this.skipNextAfterError();
          this.duration = event.duration;
          this.position = event.position;
          if (event.userAction) {
            this.$stats.collect(this.currentItem.id, 'watch', 'pause', event);
            // $stats.send('pause', {
            //   watch_position: event.position,
            //   media_id: this.currentItem.id,
            //   product_id: null, // todo:
            //   channel_id: this.currentItem.creator.id,
            //   media_type: this.currentItem.isLive ? 'live' : 'vod',
            // });
          }
          //          this.pausePlayerAnalytiks(event);
        }));
        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_EVENTS.SEEKED, (event) => {
          this.$stats.collect(this.currentItem.id, 'watch', 'seek', event);
          // $stats.send('seek', {
          //   watch_position: event.position,
          //   media_id: this.currentItem.id,
          //   product_id: null, // todo:
          //   channel_id: this.currentItem.creator.id,
          //   media_type: this.currentItem.isLive ? 'live' : 'vod',
          // });
        }));
        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_EVENTS.SEEKING, (event) => {
          this.$stats.collect(this.currentItem.id, 'watch', 'seeking', event);
        }));
        // Playing
        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_EVENTS.PLAYING, (event) => {
          this.isVideoPlaying = true;
          this.$bus.$emit(this.eventIdentifier + '_playerPlaying', event);
          this.playerStatus = 1;
          this.duration = event.duration;
          this.position = event.position;
        }));

        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_EVENTS.AD_EVENTS, (event) => {
          // this.$logger && this.$logger.info('MPV', 'adEvents', event);
          this.playerStatus = 1;
          switch (event.name) {
            case 'playing':
              this.skipNextAfterError();
              this.$bus.$emit(this.eventIdentifier + '_playerPlay', event);
              this.$bus.$emit(this.eventIdentifier + '_togglePlayerLayout', {
                value: true,
                arrows: true,
              });
              this.isAdsPlaying = true;
              break;
            case 'ended':
              this.isAdsPlaying = false;
              break;
          }
        }));

        // Play Next
        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_EVENTS.PLAYING_NEXT, (asset) => {
          // this.$logger && this.$logger.info('MPV', 'playingNext', asset);
          this.isRestricted = asset.restricted;
          this.currentOptions = asset;

          this.skipNextAfterError();
          if (this.isRestricted) {
            this.showRestrictionMessage();
          }
          if (!this.currentOptions?.preview) {
            this.setWatchedVideo();
            this.setResumeWatch();
          }
          this.$bus.$emit(this.eventIdentifier + '_playerNextPlaying', asset);
        }));

        // Play Prev
        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_EVENTS.PLAYING_PREV, (asset) => {
          this.currentOptions = asset;
          this.isRestricted = asset.restricted;

          this.skipNextAfterError();
          if (this.isRestricted) {
            this.showRestrictionMessage();
          }
          this.setWatchedVideo();
          this.setResumeWatch();
          // this.$logger && this.$logger.info('MPV', 'playingPrev', asset);
          this.$bus.$emit(this.eventIdentifier + '_playerPrevPlaying', asset);
        }));

        // EndStop
        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_EVENTS.ENDED, (event) => {
          this.$stats.collect(this.currentItem.id, 'watch', 'ended', event);

          const options = this.$stats.formatEventDate(this.currentItem);
          event.stream_time = event.stream_time || this.stream_time;
          const eventData = this.$stats.collect(this.currentItem.id, 'heartbeat', 'ended', event);
          if (eventData) {
            this.$stats.send('heartbeat', { ...options, ...eventData });
          }
          this.$stats.clearCollectedData(this.currentItem.id, 'heartbeat');

          $stats.send('watch', { ...options });
          this.$bus.$emit(this.eventIdentifier + '_playerEnded', {});
        }));

        // Error
        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_EVENTS.ERROR, (event) => {
          this.showNextVideoAfterError();
          this.$bus.$emit(this.eventIdentifier + '_playerError', {});
          this.playerStatus = 1;
        }));

        this.PMSubscribes.push(PM.inst().target(this.eventIdentifier + '_video_player').on(PLAYER_EVENTS.AREA_CLICK, (event) => {
          this.duration = event.duration;
          this.position = event.position;
        }));
      },

      isVideoRestricted(item) {
        return new Promise((resolve) => {
          let age =
            item && item.age_restriction
              ? item.age_restriction !== 'AP' && item.age_restriction !== 'None'
                ? parseInt(item.age_restriction)
                : item.age_restriction !== 'None'
                  ? 0
                  : 0
              : 0;
          if (!this.isAuth || (this.isGuest && item.noguest)) {
            resolve('RestrictedForUnknow');
          } else if (
            amsClient.isContentPremium(item) &&
            !amsClient.canPlayPremium(item)
          ) {
            resolve('RestrictedForNotFree');
          } else if (
            amsClient.isContentFreemium(item) &&
            !amsClient.canPlayFreemium(item)
          ) {
            // resolve('RestrictedForFreemium');
            resolve('RestrictedForNotFree');
          } /*else if (UserService.getAge() > -1 && UserService.getAge() < age) {
            resolve('RestrictedForAge');
          }*/ else {
            this.isShowRestrictedMessage = false;
            resolve(null);
          }
        });
      },
      // NOTE: events functions
      event_enableControls() {
        this.send(PLAYER_METHODS.CONTROLS_STATE, {
          data: {},
          name: 'enableControls',
        });
      },
      event_actionOnButton(options = {}) {
        if (options.name) {
          this.send(PLAYER_METHODS.CONTROLS_STATE, {
            data: options.data || {},
            name: options.name,
          });
        }
      },
      event_disableControls() {
        this.send(PLAYER_METHODS.CONTROLS_STATE, {
          data: {},
          name: 'disableControls',
        });
      },
      event_pauseVideo() {
        this.send(PLAYER_METHODS.PAUSE);
      },
      event_playVideo() {
        console.log('event_playVideo', this.vod);

        this.event_enableControls();
        this.send(PLAYER_METHODS.PLAY);
      },
      event_setComponentPoster(options) {
        this.tempPoster = normalizeImg(
          options.item.backdrop || options.item.poster,
          'image',
        );
      },
      event_loadPrevNextVideo(opt) {
        let item = opt.item;
        let isNext = opt.isNext;
        let options = {
          playerOptions: {},
          extraData: {},
        };

        options.playerOptions.bigButton = opt.bigButton === true;
        options.playerOptions.mainPage = opt.mainPage === true;
        options.playerOptions.autoplay = opt.autoplay === true;

        options.playerOptions.loadMode = opt.loadMode || 'default';
        item.click_to_media_start = Date.now();

        this.loadItemPlayOptions(item).then((newOpt) => {
          options = deepMerge(options, newOpt);
          this.send(PLAYER_METHODS.CONTROLS_STATE, {
            data: options,
            name: isNext ? 'loadNext' : 'loadPrev',
          });
        });
      },

      // Play Video Now
      event_playNowVideo(opt) {
        if (!opt.item) {
          return;
        }
        this.clickOnMedia = opt.clickTime || Date.now();
        this.prevOpt = opt;
        this.$logger && this.$logger.log('MPV', 'playNowVideo', opt);
        let item = opt.item;
        if (
          this.currentItem &&
          this.position &&
          this.duration &&
          !this.currentOptions?.preview
        ) {
          this.setResumeWatch();
        }
        this.currentItem = item;
        this.stream_time = 0;
        this.previewStop = false;

        this.tempPoster = normalizeImg(item.backdrop || item.poster, 'image');
        this.event_PlayNowPart2(opt);
      },
      event_PlayNowPart2(opt) {
        if (!opt.item) {
          return;
        }
        let item = opt.item;

        let options = {
          playerOptions: {},
          extraData: {},
        };
        if (this.previewStop) {
          this.previewStop = false;
        }

        this.loadItemPlayOptions(item).then(async (newOpt) => {
          if (this.isBeforeDestroy) return;

          options = deepMerge(options, newOpt);

          if (this.vod) {
            options.playerOptions.autoplay = true;
          } else {
            if (this.isVodItem && !this.isMiniatureIframe) {
              options.playerOptions.autoplay = false;
            } else {
              options.playerOptions.autoplay = true;
            }
          }
          // options.playerOptions.autoplay = true; // this.isProductVod ? this.item.playerOptions.autoplay : false;
          options.playerOptions.bigButton = opt.bigButton = this.isProductVod ? this.item.playerOptions.showPlayButton : true;
          options.playerOptions.mainPage = opt.mainPage === true;
          options.playerOptions.controls = this.isProductVod ? this.item.playerOptions.controls : !options.isLive;
          options.playerOptions.muted = true;

          if (this.item.playerOptions) {
            options.playerOptions.startPosition = this.item.playerOptions.startPosition;
          } else {
            if (opt.startPosition) {
              options.playerOptions.startPosition = opt.startPosition;
            } else {
              options.playerOptions.startPosition = 0;
            }
          }

          // this.$logger && this.$logger.info('loaded video playOptions', options);
          this.currentOptions = options;

          if (this.shopLayouts) {
            /** it can string, in this case the player load it by the id*/
            if (item.creator) {
              this.send(PLAYER_METHODS.CONFIGURATION, {
                data: {
                  creator: item.creator || null,
                },
              });
            }

            let mediaUrl = null;

            if (item.itype === 'item_mov_vod_shoprz') {
              if (item.mid && !item.recorded) {
                if (!this.getPlayTemplate?.info) {
                  if (this.setTemplate) {
                    await this.setTemplate();
                  }
                }
                if (this.getPlayTemplate?.info) {
                  mediaUrl = this.getPlayTemplate.info.vod.replace(/{MID}/gi, item.mid);
                }
              } else if (item.recorded && item.media_url) {
                mediaUrl = item.media_url;
              }
            }

            this.send(SHOP_METHODS.PLAY_EVENT, {
              item: {
                ...item,
                mediaUrl,
              },
              options,
            });
          } else {
            this.send(PLAYER_METHODS.PLAY_NEW_VIDEO, options);
          }
          this.isRestricted = options ? options.restricted : null;

          if (options && options.restricted) {
            this.showRestrictionMessage();

            this.$bus.$emit(this.eventIdentifier + '_isVideoRestricted', item);
            this.showNextVideoAfterError();
          } else {
            this.skipNextAfterError();
          }
        });
      },

      async loadItemPlayOptions(item) {
        if (!item || !item.id) {
          if (item.media) {
            item.id = item.media.split('/')[2];
          } else {
            return null;
          }
        }

        if (!item.media) {
          item.media = `/play/${item.id}/`;
        }

        let options = {
          playerOptions: {},
        };

        options.isLive = ['item_live_virtual', 'item_live', 'item_epg_shop_event'].indexOf(item.itype) !== -1;
        options.media_id = item.id;
        // options.alphaId = item.mid;
        options.itype = item.itype;
        options.drm = !!item.drm || item.mplayer === 'hooq';

        if (!!item.drm) {
          options.drm_types = item.drm_types;
        } else if (item.mplayer === 'hooq') {
          options.drm_types = ['HLS/FAIRPLAY', 'DASH/WIDEVINE'];
        }

        let screen = this.$stats.get('current.screen') || {};
        options.extraData = {
          poster: normalizeImg(item.backdrop || item.poster, 'image'),
          title: item.title,
          iid: this.getMediaId(item.iid, item.id),

          // itype: item.itype,
          screen_id: screen.id || null,
          screen_type: this.$stats.utils.normaliseScreenType(screen.link),
          click_to_media_start: item.click_to_media_start || this.clickOnMedia,
          context: Config.get('temp.context') || 'user_click', //todo
          subscription_status: amsClient.getSubscriptionStatus(item),
        };

        options.restricted = await this.isVideoRestricted(item);

        options.preview_info = item.preview_info
          ? { ...item.preview_info }
          : null;
        if (process.env.VUE_APP_CUSTOMER === 'zman') {
          options.preview =
            !!item.preview_info && item.not_free && !!options.restricted;
        } else if (process.env.VUE_APP_CUSTOMER === 'nine') {
          options.preview =
            !!item.preview_info &&
            ((this.isGuest && !item.not_free) ||
              (item.not_free && !!options.restricted));
        }
        if (options.preview) {
          options.playerOptions.autoplay = false;
        }

        /** todo: handle case when next or prev episode and live */
        let maxSeconds = Auth.get('user.preview_info.daily_seconds');
        if (!!options.preview && maxSeconds) {
          let usrPreviewInf = await amsClient.stock.getDailyPreviewTime(
            item.id,
            true,
          );

          let fullLeftSeconds = maxSeconds - usrPreviewInf.full;

          if (
            fullLeftSeconds <
            options.preview_info.preview_end - options.preview_info.preview_start
          ) {
            options.preview_info.preview_end =
              fullLeftSeconds + options.preview_info.preview_start;
          }
          if (usrPreviewInf.item) {
            if (
              usrPreviewInf.item <
              options.preview_info.preview_end -
              options.preview_info.preview_start
            ) {
              options.preview_info.preview_end =
                options.preview_info.preview_end - usrPreviewInf.item;
              options.preview_info.consumed_time = usrPreviewInf.item;
            } else {
              options.preview_info.preview_end =
                options.preview_info.preview_start;
            }
          }
          /** daily mode */
        }

        if (
          (item.not_free && !options.restricted) ||
          (this.isAuth && item.not_free && options.restricted)
        ) {
          // this.$stats.send('try_play_premium', {
          //   itype: item.itype,
          //   item_id: item.id,
          //   product_offer_result:
          //     item.not_free && !options.restricted ? 'ok' : 'not_allowed',
          // });
        }
        return options;
      },

      send(type, data) {
        data = data || {};

        const target = this.getCurrentFrame(this.eventIdentifier + '_video_player');

        PM.inst().send({
          target,
          type: type,
          data: data,
        });
      },
      showNextVideoAfterError() {
        this.skipNextAfterError();
        this.idTimeoutNewVideoAtrerError = setTimeout(() => {
          this.skipNextAfterError();
          this.$bus.$emit(this.eventIdentifier + '_showNextAfterError', {
            isAlreadyPlaying: false,
          });
        }, this.TIMEOUT_FOR_NEW_VIDEO_AFTER_ERROR);
      },
      skipNextAfterError() {
        clearTimeout(this.idTimeoutNewVideoAtrerError);
      },

      scrollToView() {
        this.$el.scrollIntoView();
      },
      calculateHeight() {
        if (window.innerWidth > 1024 && window.innerHeight > 650) {
          let playerMeta = document.querySelector('.player-meta');
          let metaHeight =
            playerMeta && playerMeta.scrollHeight ? playerMeta.scrollHeight : 200;

          let maxHeight = window.innerHeight - (80 + metaHeight);
          this.calcHeight = 'calc(' + maxHeight + 'px - 3rem)';
        } else {
          this.calcHeight = (this.$el.clientWidth * 9) / 16 + 'px' || '100%';
        }
      },

      getMediaId(base1, base2) {
        return base1 || parseInt(base2.substr(0, 8), 16);
      },
      getCurrentFrame(nameOrId) {
        let el = document.getElementById(nameOrId);
        if (!el) {
          el = document.querySelector('[name="' + nameOrId + '"]');
        }
        return el || null;
      },
      setRestrictionState(options = {}) {
        this.send(PLAYER_METHODS.SET_RESTRICTION, {
          restricted: options.restricted,
        });
      },
      setWatchedVideo() {
        if (
          !this.isRestricted &&
          this.isActiveFeature('history-list') &&
          this.isFullAuth &&
          this.prevId !== this.currentItem.id
        ) {
          this.prevId = this.currentItem.id;
          amsClient.stock.setRecent(this.currentItem.id);
        }
      },

      setResumeWatch() {
        if (
          this.currentItem.itype === 'item_mov_vod' ||
          this.currentItem.itype === 'item_mov_vod_shoprz' ||
          this.currentItem.itype === 'item_mov_episode'
        ) {
          /* if (this.isActiveFeature('resume-watch') && this.isFullAuth) {
            let position = this.position;
            if (this.duration && this.position > 5) {
              if (this.position + 300 > this.duration) {
                position = 0;
              }
              if (this.currentItem.itype === 'item_mov_vod' || this.currentItem.itype === 'item_mov_vod_shoprz') {
                amsClient.stock.setMovieResumeWatch(
                  this.currentItem.id,
                  parseInt(position),
                );
              } else if (this.currentItem.itype === 'item_mov_episode') {
                amsClient.stock.setEpisodeResumeWatch(
                  this.currentItem.id,
                  this.currentItem.id,
                  this.currentItem.id,
                  parseInt(position),
                );
              }
            }
          } */
        }
      },
      hideRestrictionMessage() {
        if (this.isShowRestrictedMessage) {
          this.isShowRestrictedMessage = false;
        }
        this.$bus.$emit('toggleAuthPopup', {
          component: 'login',
          force: false,
          formId: 'fromPlayer',
          item_id: this.currentItem.id,
        });
      },
      showRestrictionMessage() {
        this.$nextTick(() => {
          this.isShowRestrictedMessage = true;
          if (this.isRestricted === 'RestrictedForLive') {
            this.$bus.$emit('toggleAuthPopup', {
              component: 'login',
              force: true,
              message: this.$i18n.t('VIDEO.CONTENT_IS_RESTRICTED'),
              formId: 'fromPlayer',
            });
          } else if (this.isRestricted === 'RestrictedForNotFree') {

            if (this.isPriorityShowRegisterMess) {
              this.isPriorityShowRegisterMess = false;
              this.isPriorityPurchase = true;
            } else if (this.isPriorityPurchase) {
              this.isPriorityPurchase = false;
              this.$bus.$emit('showCouponPurchase', {
                itemId: this.currentItem.id,
              });
            } else if (
              !this.was5minMess &&
              process.env.VUE_APP_CUSTOMER === 'zman'
            ) {
              this.was5minMess = true;
              this.$bus.$emit('toggleAuthPopup', {
                component: 'message',
                forceCloseBtn: true,
                force: true,
                centerOn: '.media-player-video',
                message: this.$i18n.t('VIDEO.GUEST_PREVIEW_TIME_LEFT'),
                actions: {
                  close: this.$i18n.t('VIDEO.CLOSE_MESSAGE_TEXT'),
                },
              });
            }
          }
        });
      },

      sendProductOfferPlayedOnEvent() {
        if (this.currentItem && this.$stats) {
          let itemSubscription = amsClient.getSubscriptionStatus(
            this.currentItem,
          );
          if (itemSubscription !== 'free') {
            // let { products, offers } = itemSubscription;
            let { products, offers } = amsClient.getActiveProductsOffers(
              this.currentItem,
            );
            let extraData = {
              itype: this.currentItem.itype,
              item_id: this.currentItem.id,
            };
            // products.forEach((product) => {
            //   // this.$stats.send(
            //   //   'product_offer_played_on',
            //   //   Object.assign(
            //   //     {
            //   //       product_id: product,
            //   //       offer_id: null,
            //   //     },
            //   //     extraData,
            //   //   ),
            //   // );
            // });
            // offers.forEach((offer) => {
            //   this.$stats.send(
            //     'product_offer_played_on',
            //     Object.assign(
            //       {
            //         product_id: null,
            //         offer_id: offer,
            //       },
            //       extraData,
            //     ),
            //   );
            // });
          }
        }
      },
      eventPlayerMethods(method) {
        if (method in PLAYER_METHODS) {
          this.send(PLAYER_METHODS[method]);
        } else if (method === 'MUTE_OFF') {
          this.send(method);
        }
      },
    },
  };
</script>

<style lang="scss">
  @import './video';
</style>