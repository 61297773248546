const state = () => ({
  vodStoreItems: [],
  productsStoreItems: [],
});

const getters = {
  getVodStoreItems(state) {
    return state.vodStoreItems;
  },

  getProductsStoreItems(state) {
    return state.productsStoreItems;
  },
};

const actions = {};

const mutations = {
  setVodStoreItems(state, payload) {
    // state.vodStoreItems = [...payload];
    for (let i = 0; i < 5; i++) {
      state.vodStoreItems.push(payload[0]);
    }
  },

  setProductsStoreItems(state, payload) {
    // state.productsStoreItems = [...payload];

    for (let i = 0; i < 3; i++) {
      state.productsStoreItems.push(payload[0]);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
