/**
 * @param {Object} data
 * @param {String} data.title
 * @param {String} data.description
 * @param {String} data.eventLink
 * @param {Date} data.startDate
 * @param {Date} data.endDate
 * @param {String} data.timezone
 * @param {Object | null} data.summerTime
 * @param {String} data.summerTime.tzFrom
 * @param {String} data.summerTime.tzTo
 * @param {String} data.summerTime.byMonth
 */
export default (data) => `data:text/ics;charset=utf-8,BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VTIMEZONE
CALSCALE:GREGORIAN
TZID:${data.timezone}
${
  data.summerTime
    ? `BEGIN:DAYLIGHT
TZOFFSETFROM:${data.summerTime.tzFrom}
TZOFFSETTO:${data.summerTime.tzTo}
DTSTART:19700329T030000
RRULE:FREQ=DAILY;BYMONTH=${data.summerTime.byMonth}
END:DAYLIGHT`
    : ''
}
BEGIN:STANDARD
TZNAME:PST
END:STANDARD
BEGIN:DAYLIGHT
TZNAME:PDT
END:DAYLIGHT
END:VTIMEZONE
BEGIN:VEVENT
STATUS:CONFIRMED
TRIGGER:-PT15M
SEQUENCE:0
DTSTART;TZID=${data.timezone}:${data.startDate}
DTEND;TZID=${data.timezone}:${data.endDate}
SUMMARY:${data.title}
URL;VALUE=URI: ${data.eventLink.replace('#', '')}
DESCRIPTION: Link to the Event - ${data.eventLink.replace('#', '')} \\n ${
  data.description || ' '
}
X-ALT-DESC;FMTTYPE=text/html:
LOCATION:Verb marketplace
TRANSP:OPAQUE
END:VEVENT
END:VCALENDAR`;
