import Vue from 'vue';
import Auth from '@/service/authService';

export default new (class {
  constructor() {}

  sendEvent(event, params) {
    if (!Vue.prototype.$gtag) {
      console.warn('GTAG not inited');
      return;
    }

    const currentUser = Auth.get('user');

    console.log('GTAG:', event, params, currentUser);

    Vue.prototype.$gtag('event', event, {
      uid: currentUser.id,
      guest: currentUser.guest ? 1 : 0,
      ...params,
    });
  }
})();