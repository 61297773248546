/**
 * Installation procedure
 *
 * @param Vue
 */
const install = function (Vue) {
  class FixBD {
    openModal() {
      document.body.classList.add('model-opened')
      // document.body.style.overflowY = 'scroll';
      //document.body.style.position = 'fixed';
      document.getElementById('app').style.height = window.innerHeight + 'px';
    }

    closeModal() {
      document.body.classList.remove('model-opened')
      // document.body.style.overflowY = 'auto';
      //document.body.style.position = 'relative';
      document.getElementById('app').style.height = '';
    }

    openSideBar() {
      document.body.style.overflowY = 'hidden';
      document.body.style.position = 'relative';
      // document.body.classList.add('scrollstop');
    }
  }

  Vue.prototype.$body = new FixBD();
};

export default {install};
