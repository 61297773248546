<template>
  <transition
    name="vod-preview"
    @after-enter="startVideo"
  >
    <div
      v-if="visible"
      :style="{ top, left, width }"
      class="vod-preview"
      @mouseleave="hidePreview"
    >
      <carousel-item
        ref="itemPreview"
        :item="item"
        :item-type="'horizontal'"
        :is-vod-preview="true"
      />
    </div>
  </transition>
</template>
<script>
  import { mapState } from 'vuex';
  import CarouselItem from '@/components/swiper-carousel/carousel-item/carousel-item';

  export default {
    name: 'VodPreview',
    components: {
      CarouselItem,
    },
    data() {
      return {
        topOffset: 26,
        leftOffset: 40,
      }
    },
    computed: {
      ...mapState('vodPreview', {
        visible: 'visible',
        coords: 'coords',
        item: 'item',
      }),
      top() {
        return `${this.coords.top - this.topOffset}px`;
      },
      left() {
        return `${this.coords.left - this.leftOffset}px`;
      },
      width() {
        return `${2 * Math.round((this.leftOffset * 2 + this.coords.width) / 2)}px`;
      },
    },
    methods: {
      hidePreview() {
        this.$refs.itemPreview?.stopPreview();
        this.$store.dispatch('vodPreview/hide');
      },
      startVideo() {
        this.$refs.itemPreview.runPreview();
        this.$refs.itemPreview.getFeaturedProducts();
      },
    },
  }
</script>
<style lang="scss" scoped>
  .vod-preview {
    position: absolute;
    min-height: 196px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(151, 174, 202, 0.25);
    z-index: 10;

    &::v-deep .item {
      max-width: 100%;

      &.horizontal {
        .item-image {
          height: 196px;
					background-color: black;
        }

				video {
					display: block;
          width: auto;
          }

        .item-title {
          padding: 0 15px;
					background-color: #fff;
        }

        .featured-products {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }

  .vod-preview-enter-active {
    animation: preview-show 0.5s;
  }

  .vod-preview-leave-active {
    animation: preview-show 0.2s reverse;
  }

  @keyframes preview-show {
    0% {
      opacity: 0;
      transform: scale(0.85);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
</style>
