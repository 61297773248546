import store from '@/store';

export default new (class {
  setFloatingMode(value) {
    store.commit('popup/setFloatingMode', value);
  }

  setScreenType(value) {
    store.commit('popup/setScreenType', value);
  }

  setAccountPopupState(value) {
    store.commit('popup/setAccountPopupState', value);
  }
})();