<template>
  <div class="footer">
    <div class="navigateWrap">
      <nav>
        <div class="logo" @click="redirectToHome">
          <img :src="require('src/assets/logo/logo-market-footer.png')" />
        </div>

        <div class="navigate-container">
          <div class="navigate-links">
            <a v-if="!$isStandaloneStore" @click="$router.push('/screen/live/')">
              Live
            </a>
            <a v-if="!$isStandaloneStore" @click="$router.push('/stores')">
              Stores
            </a>

            <a id="myLauncher" @click="openWidget_AboutUs">About Us</a>
            <a id="myLauncher" @click="openWidget_FAQ">Help</a>
            <a :href="isLinkMarketLive">Returns</a>
            <router-link to="/blog">
              Blog
            </router-link>
            <!-- <a href="https://blog.market.live/" target="_blank">Blog</a> -->
            <!-- <router-link v-if="blogLink" :to="blogLink.link.replace('get_item', 'web')">
              Blog
            </router-link> -->
          </div>
          <div class="navigate-social-links">
            <a target="_blank" href="https://www.facebook.com/market.liveofficial">
              <img
                class="navigate-social-links-icon"
                :src="require('src/assets/icons/icons-facebook.png')"
              >
            </a>
            <a target="_blank" href="https://www.instagram.com/market.liveofficial">
              <img
                class="navigate-social-links-icon"
                :src="require('src/assets/icons/icons-instagram.png')"
              >
            </a>
            <a target="_blank" href="https://www.tiktok.com/@market.live_official">
              <img
                class="navigate-social-links-icon"
                :src="require('src/assets/icons/icons-tiktok.png')"
              >
            </a>
          </div>
        </div>
      </nav>

      <div class="sellersWrap">
        <span>Join Our MARKET Community</span>
        <router-link to="/seller-splash">
          Start Selling
          <i class="fas fa-chevron-right" />
        </router-link>
        <router-link to="/become-affiliate">
          Become an Affiliate
          <i class="fas fa-chevron-right" />
        </router-link>
      </div>
    </div>

    <div class="policy">
      <p>
        <a id="myLauncher" @click="openWidget_Privacy">Policies</a>
        <span class="bullet" />
        <a class="termLink" @click="openWidget_Terms">Terms of Use</a>
        <span class="bullet" />
        <a href="javascript:Optanon.ToggleInfoDisplay()"
          >Do Not Sell My Personal Information</a
        >
      </p>

      <div class="verbLinkWrap">
        <span>© {{ currentYear }} Market.live®</span>

        <a href="https://www.verb.tech/" target="_blank">
          A Verb Technology Company
        </a>
      </div>
    </div>
  </div>
</template>
<script>
  import Config from '@/service/config';
  import { amsClient } from '@/service/ams';

  export default {
    name: 'FooterMain',
    data() {
      return {
        isLinkMarketLive: '',
        sellerLink: '',
        currentYear: new Date().getFullYear(),
      };
    },
    computed: {
      blogLink() {
        const items = this.$store.getters['navLinks/links'];
        return items.find((item) => item.title === 'Blog') || null;
      },
    },
    created() {
      Config.loaded(() => {
        this.sellerLink = amsClient.get(
          'conf.portal.marketplace.shopify.become_seller_url',
        );
        this.isLinkMarketLive = amsClient.get(
          'conf.portal.marketplace.shopify.returns_and_exchanges_url',
        );
      });
    },
    methods: {
      openWidget_FAQ() {
        zE('webWidget', 'close');
        zE('webWidget', 'reset');
        zE('webWidget', 'show');
        zE('webWidget', 'open');
        zE('webWidget', 'helpCenter:setSuggestions', { url: false });
        zE('webWidget', 'helpCenter:setSuggestions', { search: 'FAQs1' });
      },

      openWidget_AboutUs() {
        zE('webWidget', 'close');
        zE('webWidget', 'reset');
        zE('webWidget', 'show');
        zE('webWidget', 'open');
        zE('webWidget', 'helpCenter:setSuggestions', { url: false });
        zE('webWidget', 'helpCenter:setSuggestions', { search: 'about' });
      },

      openWidget_Privacy() {
        zE('webWidget', 'close');
        zE('webWidget', 'reset');
        zE('webWidget', 'show');
        zE('webWidget', 'open');
        zE('webWidget', 'helpCenter:setSuggestions', { url: false });
        zE('webWidget', 'helpCenter:setSuggestions', { search: 'policies' });
      },
      openWidget_Terms() {
        zE('webWidget', 'close');
        zE('webWidget', 'reset');
        zE('webWidget', 'show');
        zE('webWidget', 'open');
        zE('webWidget', 'helpCenter:setSuggestions', { url: false });
        zE('webWidget', 'helpCenter:setSuggestions', { search: 'terms' });
      },

      redirectToHome() {
        if (this.$route.fullPath === '/') {
          window.scroll(0, 0);
        } else {
          this.$router.push('/');
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .footer {
    margin-top: auto;
    padding: 32px 40px 19px;
    background: #f5f5fa;
    color: $main-color-items;
    font-size: 15px;
    line-height: 25px;

    a {
      color: $main-color-items;
      cursor: pointer;
    }

    @media (max-width: 800px) {
      padding: 30px 20px 22px;
    }
  }

  .navigateWrap {
    display: flex;
    justify-content: space-between;
    padding-bottom: 32px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e9e9ec;

    nav {
      display: flex;

      .logo {
        margin-right: 40px;
        cursor: pointer;
      }

      a {
        margin-right: 20px;
        font-size: 15px;
        line-height: 18px;

        &:last-child {
          margin-right: 0;
        }
      }

      @media (max-width: 800px) {
        display: block;
        padding-bottom: 25px;
        border-bottom: 1px solid #e9e9ec;

        .logo {
          display: block;
          margin-bottom: 20px;
        }
      }
    }

    .navigate {
      &-links {
        display: flex;
        align-items: center;
        margin-top: 10px;
        flex-wrap: wrap;
        row-gap: 10px;
      }

      &-social-links {
        display: flex;
        align-items: center;
        margin-top: 20px;

        a {
          display: block;
          width: 24px;
          height: 24px;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .sellersWrap {
      display: flex;
      flex-direction: column;
      flex-basis: 250px;

      span {
        margin-bottom: 14px;
      }

      a,
      .open-popup-button {
        color: $main-color;
        cursor: pointer;

        i {
          margin-left: 10px;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }

    @media (max-width: 800px) {
      display: block;

      &.standalone-store {
        margin-bottom: 0;
        border-bottom: 0;
      }

      .sellersWrap {
        margin-top: 26px;
      }
    }
  }

  .policy {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 17px;

    .verbLinkWrap {
      display: flex;
      margin-right: 47px;
      flex-direction: column;

      span {
        color: #9c9fad;
      }

      a {
        margin-top: 5px;
      }
    }

    .bullet {
      display: inline-block;
      border-radius: 50%;
      vertical-align: super;
      margin: 0 10px;
      width: 4px;
      height: 4px;
      background-color: #d8d8e2;
    }

    @media (max-width: 800px) {
      display: block;

      p {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }

      span {
        margin-right: 0;
      }

      .bullet {
        max-width: 4px;
      }
    }

    @media (max-width: 460px) {
      a:last-of-type {
        margin-top: 5px;
      }

      span:last-of-type {
        visibility: hidden;
      }
    }
  }
</style>