<template>
  <transition
    name="loader-anima"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
  >
    <span
      class="loading-container animated "
      :style="{'color': color, position: absolute ? 'absolute' : 'relative'}"
    >
      <i class="fas fa-5x fa-spinner fa-pulse" />
    </span>
  </transition>
</template>

<script>

  export default {
    name: 'Loader',
    props: {
      color: {
        type: String,
        default: 'white',
      },
      absolute: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="scss">
  @import './loader';
</style>
