<template>
  <div class="auth-actions" :class="{ 'open': isMobileOpen }">
    <div class="auth-actions__desktop">
      <button
        v-if="!isFullAuth"
        type="button"
        class="btn auth-actions__btn"
        @click.stop="showAuthPopup()"
      >
        {{ $t('HEADER.LOGIN') }}
      </button>
      <b-dropdown v-else-if="user" right no-caret>
        <template #button-content>
          <span :title="user.username">{{ username }}</span>
          <i class="fas fa-chevron-down"/>
        </template>
        <b-dropdown-item to="/my-profile/settings" link-class="account text-overflow">
          <img :src="require('src/assets/icons/profile-actions/account.svg')" alt="Profile settings">
          {{ username }}
          <i class="far fa-chevron-right"/>
        </b-dropdown-item>
        <b-dropdown-divider/>
        <b-dropdown-item to="/my-profile/orders">
          <img :src="require('src/assets/icons/profile-actions/bookmark.svg')" alt="My orders">
          My orders
        </b-dropdown-item>
        <b-dropdown-item to="/my-profile/billing-address">
          <img :src="require('src/assets/icons/profile-actions/billing.svg')" alt="Billing-address">
          Billing Address
        </b-dropdown-item>
        <b-dropdown-item v-if="!$isStandaloneStore" :to="'/seller-splash'">
          <img :src="require('src/assets/icons/profile-actions/user-plus.svg')" alt="Become seller">
          Become seller
        </b-dropdown-item>
        <b-dropdown-item v-if="isConfLoaded" @click="logout()">
          <img :src="require('src/assets/icons/profile-actions/sign-out.svg')" alt="Logout">
          Logout
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div
      v-if="isFullAuth && user"
      class="auth-actions__mobile"
    >
      <div class="auth-actions__header" @click="isMobileOpen = false">
        <i class="fas fa-chevron-left"/>
        <span>My Account</span>
      </div>
      <router-link class="auth-actions__user" to="/my-profile">
        <div class="logo">
          <!--<img src="">-->
        </div>
        <div class="name text-overflow">
          {{ user.username }}
        </div>
        <div class="email text-overflow">
          {{ user.username }}
        </div>
      </router-link>
      <div class="auth-actions__links">
        <router-link to="/my-profile/settings" @click.native="isMobileOpen = false">
          Account settings
          <i class="fas fa-chevron-right"/>
        </router-link>
        <router-link to="/my-profile/orders" @click.native="isMobileOpen = false">
          My orders
          <i class="fas fa-chevron-right"/>
        </router-link>
        <router-link to="/my-profile/billing-address" @click.native="isMobileOpen = false">
          Billing address
          <i class="fas fa-chevron-right"/>
        </router-link>
        <router-link v-if="!$isStandaloneStore" to="/seller-splash" @click.native="isMobileOpen = false">
          Become seller
          <i class="fas fa-chevron-right"/>
        </router-link>
      </div>
      <a class="auth-actions__logout" @click="logout()">
        Logout
        <img :src="require(`@/assets/icons/icon-login-out.svg`)" alt="">
      </a>
    </div>
  </div>
</template>
<script>
  import Config from '@/service/config';
  import { AuthVariableMixin } from '@/plugins/mixin';
  import { amsClient } from '@/service/ams';
  import Auth from '@/service/authService';

  export default {
    name: 'AuthActions',
    mixins: [AuthVariableMixin],
    props: {
      isConfLoaded: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {
        sellerLink: '',
        isMobileOpen: false,
        user: Auth.get('user'),
      };
    },
    computed:{
      username(){
        return this.user.name || this.user.username
      },
    },
    mounted() {
      Config.loaded(() => {
        this.sellerLink = amsClient.get('conf.portal.marketplace.shopify.become_seller_url');
      });
      this.$bus.$on('ToggleMobileAuthActions', (data) => {
        this.isMobileOpen = data;
      });
      amsClient.on('auth_state_change', () => this.user = Auth.get('user'));
    },
    destroyed() {
      this.$bus.$off('ToggleMobileAuthActions');
    },
    methods: {
      showAuthPopup() {
        this.$emit('showAuthPopup');
      },
      logout() {
        this.isMobileOpen = false;
        this.$emit('logout');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .auth-actions {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 180px;
    height: 100%;
    padding: 0 32px;

    @media (max-width: $md-breakpoint) {
      display: none;
      position: fixed;
      top: 55px;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 100%;
      height: auto;
      padding: 0;

      &.open {
        display: flex;
        height: calc(100vh - 50px);
      }
    }

    &__btn,
    &::v-deep .dropdown-toggle {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 8px 0;
      border: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
      line-height: 24px;
      font-size: 14px;
      font-weight: 500;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: $main-color;
      }

      &::after {
        display: none !important;
      }

      span {
        display: inline-block;
        max-width: 110px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      i {
        margin-left: 5px;
        font-size: 12px;
      }
    }

    &::v-deep .dropdown-menu {
      width: 250px;
      padding: 0 15px 12px;
      background-color: $background-color;
      border-radius: 0;
      border: none;
      box-shadow: none;
      outline: none;
      overflow: hidden;

      .dropdown-divider {
        margin: 0 0 10px;
        border-top-color: #DAE0EB;
      }

      a {
        position: relative;
        display: block;
        padding: 8px 0 8px 25px;
        background-color: transparent !important;
        line-height: 16px;
        font-size: 16px;
        font-weight: normal;
        color: $main-text-color;

        &:hover {
          opacity: 0.8;
        }

        &.account {
          padding: 12px 10px 12px 25px;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
        }

        img,
        i {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        i {
          left: auto;
          right: 0;
          width: 6px;
          font-size: 12px;
        }
      }
    }

    &__desktop {
      @media (max-width: $md-breakpoint) {
        display: none;
      }
    }

    &__mobile {
      display: none;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 50px);
      padding: 0 20px;
      background-color: #fff;

      @media (max-width: $md-breakpoint) {
        display: flex;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      min-height: 65px;
      font-size: 24px;
      font-weight: 600;
      color: $main-text-color;

      i {
        margin-right: 15px;
        font-size: 16px;
      }
    }

    &__user {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 80px;
      margin-bottom: 5px;
      padding: 25px 16px 25px 74px;
      background-color: $block-background-color;
      line-height: 11px;

      .logo {
        position: absolute;
        top: 19px;
        left: 20px;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: #aaa;
        overflow: hidden;
      }

      .name {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 600;
        color: $main-text-color;
      }

      .email {
        font-size: 14px;
        font-weight: 500;
        color: #919BAA;
      }
    }

    &__links {
      flex: 1;
      overflow-y: auto;

      a {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        min-height: 60px;
        padding: 14px 0;
        border-bottom: 1px solid #E6EBF2;
        font-size: 15px;
        line-height: 32px;
        font-weight: 500;
        color: $main-text-color !important;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    &__logout {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      margin-bottom: 30px;
      padding-left: 25px;
      padding-right: 20px;
      background-color: #F3F5F9;
      font-size: 15px;
      font-weight: 600;
      line-height: 30px;
      color: $main-text-color !important;
    }
  }
</style>