<template>
  <div class="seller-splash">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    
    <div class="seller-splash__banner">
      <div class="seller-splash__container">
        <!-- <div class="seller-splash__image-container">
          <img src="@/assets/images/seller-splash/banner.jpg" alt="" class="seller-splash__image">
        </div> -->

        <div>
          <h1 class="seller-splash__title">
            MAKE YOUR BRAND COME <span class="seller-splash__title--red">aLIVE</span>
          </h1>

          <p class="seller-splash__description">
            You`re more than a collection of still images and carefully chosen paragraphs. With MARKET, you can entertain, educate, and delight your customers and followers, all while showcasing your sparkling personality on the largest social livestream shopping and selling platform. Apply to become part of our brand or creator community below
          </p>
        </div>
      </div>
    </div>
    <div class="seller-splash__container">
      <div class="seller-splash__tab-buttons">
        <button
          class="seller-splash__tab-button"
          :class="{ 'seller-splash__tab-button--active': isSellerActive }"
          @click="handleSellerActive"
        >
          I`M A BRAND
        </button>

        <button
          class="seller-splash__tab-button"
          :class="{ 'seller-splash__tab-button--active': isCreatorActive }"
          @click="handleCreatorActive"
        >
          I`M A CREATOR
        </button>
      </div>

      <div class="seller-splash__tabs">
        <section v-if="isSellerActive" class="seller-splash__seller-container">
          <h3 class="seller-splash__section-title">
            EXPAND YOUR AUDIENCE
          </h3>

          <div class="seller-splash__video-container video-container">
            <video 
              ref="sellerVideo" 
              :controls="controls"
              playsinline
              :poster="require('@/assets/images/seller-splash/video-poster-seller.png')"
              class="seller-splash__seller-video"
              @playing="handlePlayingVideo"
              @seeking="handleSeekingVideo"
              @play="togglePlayButton(true)"
              @pause="handlePauseVideo('sellerVideo')"
              @leavepictureinpicture="handleLeaveVideoActions"
            />
            <div v-if="!hideActiveVideo" class="video-container__play-overlay">
              <span class="video-container__play" @click="handlePlayVideo('sellerVideo')">
                <img src="@/assets/icons/playback-icon.png">
              </span>
            </div>
          </div>

          <p class="seller-splash__seller-description">
            We`re acquiring shoppers every day, so our community is always growing and ready to expand your current reach. And since you can simulcast on your social networks, you`ll be able to convert more viewers than ever. This is your chance to be an early adopter of the future of online selling — it`s not just a sales channel, it`s a movement!
          </p>

          <h3 class="seller-splash__section-title">
            THE FUTURE OF ECOMMERCE <br> IS LIVESTREAM SELLING
          </h3>

          <div class="seller-splash__seller-grid seller-grid">
            <div class="item seller-grid__first-image-container">
              <img src="@/assets/images/seller-splash/seller-image-1.png" alt="" class="seller-grid__image">
            </div>
            <div class="item seller-grid__block-1">
              <div class="feature feature--width-250">
                <div class="feature__icon-container">
                  <img :src="require('@/assets/icons/seller-splash/livestream.svg')" alt="" class="feature__icon">
                </div>

                <h4 class="feature__title">
                  CUSTOM LIVESTREAM <br> SOLUTION
                </h4>

                <p class="feature__description">
                  Join our thriving community of shoppers and sellers and set up your own livestream storefront with shoppable replays, so you can make money even while you sleep! Our browser-based solution makes it easy and seamless to connect with your community.
                  No app Required.
                </p>
              </div>
            </div>
            <div class="item seller-grid__block-2">
              <div class="feature feature--width-250">
                <div class="feature__icon-container">
                  <img :src="require('@/assets/icons/seller-splash/rate.svg')" alt="" class="feature__icon">
                </div>

                <h4 class="feature__title">
                  INCREASE CONVERSION <br> RATES & DECREASE RETURNS
                </h4>

                <p class="feature__description">
                  Convert and expand your audience with conversion rates as high as 20-30%, and decrease your returns to less than 10%.
                </p>
              </div>
            </div>
            <div class="item seller-grid__block-3">
              <div class="feature feature--width-210">
                <div class="feature__icon-container">
                  <img :src="require('@/assets/icons/seller-splash/currency.svg')" alt="" class="feature__icon">
                </div>

                <h4 class="feature__title">
                  NO RISK <br> & NO HIDDEN FEES
                </h4>

                <p class="feature__description">
                  We provide all of the tools and training you need, with no onboarding fees or hidden
                  upfront costs. We only make
                  money when you sell!
                </p>
              </div>
            </div>
            <div class="item seller-grid__block-4">
              <div class="feature feature--width-230">
                <div class="feature__icon-container">
                  <img :src="require('@/assets/icons/seller-splash/reusable.svg')" alt="" class="feature__icon">
                </div>

                <h4 class="feature__title">
                  REUSABLE <br> CONTENT
                </h4>

                <p class="feature__description">
                  Shoppers can continue to watch and shop your recorded events, driving sales even when your work is finished!
                </p>
              </div>
            </div>
            <div class="item seller-grid__second-image-container">
              <img src="@/assets/images/seller-splash/seller-image-2.png" alt="" class="seller-grid__image">
            </div>
            <div class="item seller-grid__third-image-container">
              <img src="@/assets/images/seller-splash/seller-image-3.png" alt="" class="seller-grid__image">
            </div>
            <div class="item seller-grid__block-5">
              <div class="feature feature--width-200">
                <div class="feature__icon-container">
                  <img :src="require('@/assets/icons/seller-splash/integration.svg')" alt="" class="feature__icon">
                </div>

                <h4 class="feature__title">
                  EASY INTEGRATION
                </h4>

                <p class="feature__description">
                  Simple Shopify sync for easy
                  inventory importing and
                  order exporting.
                </p>
              </div>
            </div>
            <div class="item seller-grid__block-6">
              <div class="feature feature--width-240">
                <div class="feature__icon-container">
                  <img :src="require('@/assets/icons/seller-splash/live-studio.svg')" alt="" class="feature__icon">
                </div>

                <h4 class="feature__title">
                  ENGAGE YOUR <br> CURRENT AUDIENCE
                </h4>

                <p class="feature__description">
                  Leverage your existing reach by simulcasting your events, all while continuing to build your audience with our existing community.
                </p>
              </div>
            </div>
          </div>

          <a :href="toSellerLink" class="seller-splash__seller-button" target="_blank">
            APPLY TO SELL
          </a>

          <div class="seller-splash__brands">
            <h3 class="seller-splash__section-title">
              <span class="seller-splash__section-title--light">JOIN HUNDREDS OF</span> <br> WELL-KNOWN BRANDS
            </h3>

            <div class="seller-splash__slider">
              <div class="seller-splash__slider-item">
                <img src="@/assets/images/seller-splash/slider/slider-4.png" alt="" class="seller-splash__slider-image">
              </div>

              <div class="seller-splash__slider-item">
                <img src="@/assets/images/seller-splash/slider/slider-3.png" alt="" class="seller-splash__slider-image">
              </div>

              <div class="seller-splash__slider-item">
                <img src="@/assets/images/seller-splash/slider/slider-2.png" alt="" class="seller-splash__slider-image">
              </div>

              <div class="seller-splash__slider-item">
                <img src="@/assets/images/seller-splash/slider/slider-1.png" alt="" class="seller-splash__slider-image">
              </div>
            </div>
          </div>

          <div class="seller-splash__seller-banner">
            <div class="seller-splash__image-container">
              <img src="@/assets/images/seller-splash/seller-banner.png" alt="" class="seller-splash__image">
            </div>

            <div class="seller-splash__banner-content">
              <h2 class="seller-splash__banner-title">
                Join MARKET`S Seller Community
              </h2>

              <a class="seller-splash__banner-button" :href="toSellerLink" target="_blank">
                APPLY TO SELL
              </a>
            </div>
          </div>
        </section>

        <section v-if="isCreatorActive" class="seller-splash__creator-container">
          <div class="seller-splash__creator-presentation">
            <div class="seller-splash__image-container">
              <img src="@/assets/images/seller-splash/creator-presentation.png" alt="" class="seller-splash__image">
            </div>

            <div class="seller-splash__presentation-content">
              <h3 class="seller-splash__presentation-title">
                TURN YOUR FOLLOWERS INTO DOLLARS
              </h3>

              <p class="seller-splash__presentation-description">
                Start making money on the largest livestream social shopping platform! Select the products that you love from over 300 brands, add them to your own storefront and start selling in 60 seconds! Best part, you get paid and don`t even have to do any of the shipping.
              </p>
            </div>
          </div>

          <h3 class="seller-splash__section-title">
            EXPAND YOUR AUDIENCE
          </h3>

          <div class="seller-splash__creator-video-container video-container">
            <video 
              ref="creatorVideo" 
              :controls="controls" 
              playsinline
              :poster="require('@/assets/images/seller-splash/video-poster-creator.png')"
              class="seller-splash__creator-video" 
              @pause="handlePauseVideo('creatorVideo')"
              @playing="handlePlayingVideo"
              @seeking="handleSeekingVideo"
              @leavepictureinpicture="handleLeaveVideoActions"
            />

            <div v-if="!hideActiveVideo" class="video-container__play-overlay">
              <span class="video-container__play" @click="handlePlayVideo('creatorVideo')">
                <img src="@/assets/icons/playback-icon.png">
              </span>
            </div>
          </div>

          <p class="seller-splash__creator-description">
            Our community is always growing and ready to expand your current reach. And Since you can simulcast on your social networks, you`ll be able to convert more viewers than ever. This is your chance to be an early of the future of online selling — it`s not just a sales channel, it`s a movement!
          </p>

          <div class="seller-splash__works">
            <h3 class="seller-splash__section-title">
              HOW IT WORKS
            </h3>
            
            <div class="seller-splash__works-image-container">
              <img src="@/assets/images/seller-splash/works-big.png" alt="" class="seller-splash__image">
              
              <div class="seller-splash__works-small-image-container">
                <img src="@/assets/images/seller-splash/works-small.png" alt="" class="seller-splash__image">
              </div>
            </div>

            <div class="seller-splash__works-grid">
              <div class="seller-splash__works-item">
                <div class="feature">
                  <div class="feature__icon-container">
                    <img :src="require('@/assets/icons/seller-splash/livestream.svg')" alt="" class="feature__icon">
                  </div>

                  <h4 class="feature__title">
                    NO APP REQUIRED
                  </h4>

                  <p class="feature__description">
                    Our browser-based solution makes it easy and seamless to connect with your followers. Shoppers can even watch and shop on replay!
                  </p>
                </div>
              </div>
              <div class="seller-splash__works-item">
                <div class="feature">
                  <div class="feature__icon-container">
                    <img :src="require('@/assets/icons/seller-splash/rate.svg')" alt="" class="feature__icon">
                  </div>

                  <h4 class="feature__title">
                    INCREASE CONVERSION RATES
                  </h4>

                  <p class="feature__description">
                    Convert and expand your audience with conversion rates as high as 20-30%.
                  </p>
                </div>
              </div>
              <div class="seller-splash__works-item">
                <div class="feature">
                  <div class="feature__icon-container">
                    <img :src="require('@/assets/icons/seller-splash/currency.svg')" alt="" class="feature__icon">
                  </div>

                  <h4 class="feature__title">
                    NO RISK & NO HIDDEN FEES
                  </h4>

                  <p class="feature__description">
                    We provide all of the tools and training you need, with no onboarding fees or hidden upfront costs. We only make
                    money when you sell!
                  </p>
                </div>
              </div>
              <div class="seller-splash__works-item">
                <div class="feature">
                  <div class="feature__icon-container">
                    <img :src="require('@/assets/icons/seller-splash/reusable.svg')" alt="" class="feature__icon">
                  </div>

                  <h4 class="feature__title">
                    REUSABLE CONTENT
                  </h4>

                  <p class="feature__description">
                    Shoppers can continue to watch and shop your recorded events, driving sales even when your work is finished!                </p>
                </div>
              </div>
              <div class="seller-splash__works-item">
                <div class="feature">
                  <div class="feature__icon-container">
                    <img :src="require('@/assets/icons/seller-splash/integration.svg')" alt="" class="feature__icon">
                  </div>

                  <h4 class="feature__title">
                    EASY SETUP
                  </h4>

                  <p class="feature__description">
                    No need to worry about inventory or shipping when you add products from other brands to your storefront, and you can ready to sell in 10-15 minutes.                </p>
                </div>
              </div>
              <div class="seller-splash__works-item">
                <div class="feature">
                  <div class="feature__icon-container">
                    <img :src="require('@/assets/icons/seller-splash/live-studio.svg')" alt="" class="feature__icon">
                  </div>

                  <h4 class="feature__title">
                    ENGAGE YOUR CURRENT AUDIENCE
                  </h4>

                  <p class="feature__description">
                    Leverage your existing reach by simulcasting your events on your social platforms to monetize all channels, all while continuing to build your audience with our existing community.                </p>
                </div>
              </div>
            </div>

            <a class="seller-splash__works-button" :href="toCreatorLink" target="_blank">
              APPLY TO SELL
            </a>
          </div>

          <div class="seller-splash__brands">
            <h3 class="seller-splash__section-title">
              <span class="seller-splash__section-title--light">CHOOSE YOUR FAVORITES FROM <br> MORE THAN</span> MILLIONS OF PRODUCTS
            </h3>

            <div class="seller-splash__slider">
              <div class="seller-splash__slider-item">
                <img src="@/assets/images/seller-splash/slider/slider-4.png" alt="" class="seller-splash__slider-image">
              </div>

              <div class="seller-splash__slider-item">
                <img src="@/assets/images/seller-splash/slider/slider-3.png" alt="" class="seller-splash__slider-image">
              </div>

              <div class="seller-splash__slider-item">
                <img src="@/assets/images/seller-splash/slider/slider-2.png" alt="" class="seller-splash__slider-image">
              </div>

              <div class="seller-splash__slider-item">
                <img src="@/assets/images/seller-splash/slider/slider-1.png" alt="" class="seller-splash__slider-image">
              </div>
            </div>
          </div>

          <div class="seller-splash__creator-banner">
            <div class="seller-splash__image-container">
              <img src="@/assets/images/seller-splash/creator-banner.png" alt="" class="seller-splash__image seller-splash__creator-banner-image">
            </div>

            <div class="seller-splash__banner-content seller-splash__banner-content--width-400">
              <h2 class="seller-splash__banner-title seller-splash__banner-title--black">
                Join MARKET`S <br> Creator Community
              </h2>

              <a
                class="seller-splash__banner-button seller-splash__banner-button--black"
                :href="toCreatorLink"
                target="_blank"
              >
                APPLY TO SELL
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import Config from '@/service/config';
  import { amsClient } from '@/service/ams';
  import device from 'src/service/device-service';
  import Swiper from 'swiper';
  import { $stats } from '../../plugins/analytics/vue-analytics-plugin';
  import HlsVideoService from '@/service/hls-video';

  const SellerSplashHlsService = new HlsVideoService();

  export default {
    name: 'SellerSplash',
    data() {
      return {
        isSellerActive: true,
        isCreatorActive: false,

        model: null,
        toSellerLink: '',
        toCreatorLink: '',
        swiper: null,
        controls: false,
        seeking: false,
        hideActiveVideo: false,
        accordionState: {
          accordion1: false,
          accordion2: false,
          accordion3: false,
          accordion4: false,
          accordion5: false,
          accordion6: false,
          accordion7: false,
        },
        isShowSellerSelectTypePopup: false,
        imagesLinks: {
          splashHeader: 'https://production-verb-vod.vidnt.net/posters/verb.131581.cust.original.png',
          splashFooter: 'https://production-verb-vod.vidnt.net/posters/verb.131571.cust.original.png',
          splashHeaderMob: 'https://production-verb-vod.vidnt.net/posters/verb.132091.cust.original.png',
          splashFooterMob: 'https://production-verb-vod.vidnt.net/posters/verb.132081.cust.original.png',
          firstRoundImage: 'https://production-verb-vod.vidnt.net/posters/verb.131561.cust.original.png',
          secondRoundImage: 'https://production-verb-vod.vidnt.net/posters/verb.131551.cust.original.png',
          pricingBackground: 'https://production-verb-vod.vidnt.net/posters/verb.131591.cust.original.png',
        },
        metadata: {
          title: 'Become a Seller',
        },
      }
    },
    computed: {
      isMobile() {
        return device.isAnyMobile();
      },
      smallBreakpoint() {
        return window.innerWidth <= 600;
      },
      largeBreakpoint() {
        return window.innerWidth <= 992;
      },
    },
    created() {
      $stats.send('page_opened', {
        page_type: 'seller-splash',
      });
      this.setImageResolution();
    },
    mounted() {
      Config.loaded(() => {
        this.toSellerLink = amsClient.get('conf.portal.marketplace.shopify.become_seller_url');
        this.toCreatorLink = amsClient.get('conf.portal.marketplace.shopify.become_creator_url');
      });

      this.handleSellerActive();


      this.swiper = new Swiper('.swiper', {
        direction: 'horizontal',
        loop: false,
        breakpoints: {
          375: {
            slidesPerView: 1,
          },
          700: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        },
      });

      /*this.$bus.$emit('toggleHeaderVisibility', {
        isHide: false,
      });

      this.$bus.$emit('removeAppMarginTop', 'offMarginTop');*/
    },
    destroyed() {
      /*this.$bus.$emit('toggleHeaderVisibility', {
        isHide: true,
      });

      this.$bus.$emit('removeAppMarginTop', '');*/
    },
    methods: {
      handleCreatorActive() {
        this.isSellerActive = false;
        this.isCreatorActive = true;

        this.controls = false;
        this.hideActiveVideo = false;

        setTimeout(async () => {
          const video = this.$refs.creatorVideo;

          SellerSplashHlsService.bindVideo(video, 'https://production-verb-vod.vidnt.net/content/verb-AweSH1-XZb/index.m3u8');

          if (SellerSplashHlsService.canPlay()) {
            await SellerSplashHlsService.setVideoSrc();
          }
        });
      },

      handleSellerActive() {
        this.isSellerActive = true;
        this.isCreatorActive = false;

        this.controls = false;
        this.hideActiveVideo = false;

        setTimeout(async () => {
          const video = this.$refs.sellerVideo;

          SellerSplashHlsService.bindVideo(video, 'https://production-verb-vod.vidnt.net/content/verb-gg6uH1-8Eb/index.m3u8');

          if (SellerSplashHlsService.canPlay()) {
            await SellerSplashHlsService.setVideoSrc();
          }
        });
      },
     

      handlePlayVideo(value) {
        if (!value) {
          this.hideActiveVideo = true;

          this.controls = true;

          return;
        }

        const video = this.$refs[value];

        video.play();

        this.hideActiveVideo = true;

        this.controls = true;
      },

      handleLeaveVideoActions(event) {
        if (event.target.paused) {
          this.togglePlayButton(false)
        }
      },

      handleSeekingVideo() {
        this.seeking = true;
      },
      handlePlayingVideo() {
        this.seeking = false;
      },

      handlePauseVideo() {
        if (document.pictureInPictureElement) {
          return;
        }


        setTimeout(() => {
          if (!this.seeking) {
            this.togglePlayButton(false)
          }
        })
      },

      togglePlayButton(value) {
        this.hideActiveVideo = value;

        this.controls = value;
      },

      setImageResolution() {
        let resolution;

        for (const [img, url] of Object.entries(this.imagesLinks)) {
          const needsHighResolution = ['splashHeader', 'splashFooter', 'splashHeaderMob', 'splashFooterMob'].includes(img);
          if (this.isMobile) {
            resolution = 'SD';
          } else {
            resolution = needsHighResolution ? 'FHD' : 'HD';
          }

          this.imagesLinks[img] = url.replace("original", resolution);
        }
      },
      toggleAccordion(e) {
        for (let a in this.accordionState){
          if(e.target.dataset.accordion === a) {
            this.accordionState[e.target.dataset.accordion] = !this.accordionState[e.target.dataset.accordion];

            continue;
          };

          this.accordionState[a] = false;
        };
      },
      findPos(obj) {
        var curtop = 0;
        if (obj.offsetParent) {
          do {
            curtop += obj.offsetTop;
          } while (obj = obj.offsetParent);
          return [curtop];
        }
      },
      scrollToPricing(e) {
        e.preventDefault();

        window.scroll(0, this.findPos(document.getElementById("pricing")));
      },
      openPopup() {
        this.isShowSellerSelectTypePopup = true;
      },
      closePopup() {
        this.isShowSellerSelectTypePopup = false;
      },
    },
  }
</script>

<style scoped lang='scss'>
  @import './SellerSplash.scss';
</style>
