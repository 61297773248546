// State
const state = () => ({
  visible: false,
  coords: {
    top: 0,
    left: 0,
    width: 0,
  },
  item: null,
});

// Getters
const getters = {}

// Actions
const actions = {
  show({ state, commit }, { visible, rect, item }) {
    commit('setVisible', visible);
    commit('setItem', item);
    commit('setCoords', rect);
  },
  hide({ commit }) {
    commit('setVisible', false);
  },
}

// mutations
const mutations = {
  setVisible(state, visible) {
    state.visible = visible;
  },
  setItem(state, item = null) {
    state.item = item;
  },
  setCoords(state, coords) {
    const containerRect = document.getElementById('app-content').getBoundingClientRect();
    
    if(coords.left <= 0) { state.visible = false }
      state.coords = {
        top: Math.abs(containerRect.top - coords.top),
        left: Math.abs(containerRect.left - coords.left),
        width: coords.width,
      };
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}