import { amsClient } from '@/service/ams';

const state = () => ({
  dataAreasLive: true,
  finishedLives: [],
  comingSoonItems: [],
  airItems: [],
  currentLive: null,
  playingLive: false,
  durationChangesTrigger: false,
});

const getters = {
  areasLive(state) {
    return state.comingSoonItems?.length || state.airItems?.length;
    // return state.dataAreasLive;
  },
  currentLive(state) {
    return state.currentLive;
  },
  playingLive(state) {
    return state.playingLive;
  },

  finishedLives(state) {
    return state.finishedLives;
  },
  finishedCurrentLive(state) {
    return (payload) => {
      return state.finishedLives.find((live) => live.id === payload.id);
    };
  },

  durationChangesTrigger(state) {
    return state.durationChangesTrigger;
  },
};

const mutations = {
  clearFinishedLives(state) {
    state.finishedLives = [];
  },
  setDataAreasLive(state, payload) {
    state.dataAreasLive = payload;
  },
  setCurrentLive(state, payload) {
    state.currentLive = payload;
  },
  setPlayingLive(state, value = false) {
    state.playingLive = value;
  },
  setItems(state, payload) {
    state[payload.type] = payload.items;
  },

  setLives(state, payload) {
    const duplicateLive = state.finishedLives.findIndex(
      (live) => live.id === payload.id,
    );

    if (duplicateLive < 0) {
      state.finishedLives.push(payload);
    }
  },

  setDurationChangesTrigger(state, payload) {
    state.durationChangesTrigger = payload;
  },

  changeStatusLive(state, payload) {
    state.finishedLives = state.finishedLives.map((live) => {
      if (live.id === payload.id) {
        return {
          ...payload,
        };
      }

      return live;
    });
  },
};

const actions = {
  async getDataAreasLive({ commit }, payload) {
    try {
      const subData = await Promise.all(
        payload?.map((nav) => amsClient.callAms(nav.link)) || [],
      );
      const checkLive = subData.some((subArr) => subArr?.items.length);
      if (!checkLive) commit('setDataAreasLive', false);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
