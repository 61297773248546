import device from 'src/service/device-service';
import Config from 'src/service/config';

/**
 * Generate part of shared link based on `itype` and `id`.

 * @param {String} link  Link template for replacing
 * @param {Object} item  Item from AMS for generation link
 * @returns {String} The main part for sharing
 */
export function generateShareLink(link, item) {
  let type = ['item_live_virtual', 'item_live'].indexOf(item.itype) !== -1 ? 'channel' : item.itype.split('_')[0];
  let id = type === 'screen' ? item.slug : item.id;
  return link.replace('{{type}}', type).replace('{{id}}', id);
}

/**
 * Generation deep link for Android.
 * Url like `http://vimmiapp.ais-vidnt.com/open/item/?id=5d5fa5138f0ff338a99fa2fe`,
 * The main part get from `Config`
 * @param {Object} item  item for generation
 */
export function generateAndroidDeepLink(item) {
  return Config.get('deep_link.android') ? generateShareLink(Config.get('deep_link.android'), item) : null; //`http://vimmiapp.ais-vidnt.com/` + link;
}

/**
 * Generation deep link for Browser.
 * Url like `https://dev-trk.vidnt.com/sharepoint/open/item/5d5fa5138f0ff338a99fa2fe`,
 * The main part get from `Config`
 * @param {Object} item  item for generation
 */
export function generateBrowserDeepLink(item) {
  return Config.get('deep_link.link') ? generateShareLink(Config.get('deep_link.link'), item) : null;
}

/**
 * Generation deep link for IOS.
 * Url like `vimmiapp://open/item/?id=5d5fa5138f0ff338a99fa2fe`,
 * The main part get from `Config`
 * @param {Object} item  item for generation
 */
export function generateIosDeepLink(item) {
  return Config.get('deep_link.ios') ? generateShareLink(Config.get('deep_link.ios'), item) : null; //'vimmiapp://' + link;
}

/**
 * Generation deep link based on User device. If Android- android Link, if IOS - ios Link, else browser link
 * The main part get from `Config`
 * @param {Object} item  item for generation
 */
export function getDeepLink(item) {
  return device.isIOS()
    ? generateIosDeepLink(item)
    : device.isAndroid()
      ? generateAndroidDeepLink(item)
      : generateBrowserDeepLink(item);
}

export function getMetaIos(data) {
  return Config.get('smartbanner.iosId') + ', app-argument=' + generateIosDeepLink(data);
}

export function getMetaAndroid(data) {
  return generateAndroidDeepLink(generateBrowserDeepLink(data));
}

export default {
  getDeepLink,
  generateShareLink,
  generateAndroidDeepLink,
  generateIosDeepLink,
  generateBrowserDeepLink,
  getMetaIos,
  getMetaAndroid,
};
