import scriptManager from '../../service/scriptManager';

const install = function(Vue, { measurementId }) {
  const script = scriptManager.addScript('google-analytics', `https://www.googletagmanager.com/gtag/js?id=${measurementId}`, true);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}

    gtag('js', new Date());
    gtag('config', measurementId);

    Vue.prototype.$gtag = gtag;
  }
}

export default { install }