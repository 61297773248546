import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App';
import axios from 'axios';
import 'intersection-observer';
import VueAxios from 'vue-axios';
import vueHeadful from 'vue-headful';
import vueScrollBehavior from 'vue-scroll-behavior';

import {
  AlertPlugin,
  CollapsePlugin,
  DropdownPlugin,
  ModalPlugin,
  NavbarPlugin,
  SpinnerPlugin,
  FormSelectPlugin,
  VBVisiblePlugin,
} from 'bootstrap-vue';
import ObserveVisibility from 'vue-observe-visibility';

import { LoggerVuePlugin } from 'logger-js';
import router from './router';
import store from './store';
import './directives';
import './filters';
import i18n from './lang/index';
import Config from './service/config';
import VueErrorHandler from './service/vue-error-handler';
import ErrorPlugin from './plugins/errorPlugin';
import BusPlugin from './plugins/event-bus';
import BodyPlugin from './plugins/body-style';
import GoogleAnalyticsPlugin from './plugins/google-analytics';
import { default as FeatureTogglePlugin } from 'src/plugins/feature-toggle';
import VueAnalyticsPlugin from 'src/plugins/analytics/vue-analytics-plugin';
import ZendeskPlugin from '@/plugins/zendesk';
import { localStore } from 'vimmi-web-utils/esm/localStore';
import lifecycle from 'page-lifecycle';
import VueClipboard from 'vue-clipboard2';
import { amsClient } from '@/service/ams';
import device from '@/service/device-service';
import { FormCheckboxPlugin, ToastPlugin, ImagePlugin } from 'bootstrap-vue';
import SentryPlugin from 'src/plugins/sentry';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { datadogRum } from '@datadog/browser-rum';
import OverLoadedSystemPage from './views/OverLoadedSystemPage/OverLoadedSystemPage.vue';

console.info(
  `PORTAL version: ${process.env.VUE_APP_PROJECT_VERSION}, build date: ${process.env.VUE_APP_PROJECT_DATE}`,
);

Vue.use(async function () {
  const FB_APP_ID = { dev: '329619662230361', prod: '639647490688408' };

  const getEnv = () => {
    return new Promise((resolve) => {
      Config.loaded(() => {
        resolve(amsClient.get('conf.portal.env'));
      });
    });
  };

  const env = await getEnv();
  const appId = env === 'dev' ? FB_APP_ID.dev : FB_APP_ID.prod;

  FB.init({
    appId,
    cookie: true,
    xfbml: true,
    version: 'v12.0',
  });

  // FB.AppEvents.logPageView();
});

const isProduction = process.env.NODE_ENV === 'production';
const isMarketProduction = ![
  'int-verb.vidnt.com',
  'int-verb.vidnt.com',
  'localhost',
].includes(location.hostname);

Vue.prototype.$isProduction = isProduction;

Vue.use(LoggerVuePlugin, {
  pluginName: '$logger',
  project: {
    name: 'Portal',
    style: 'background: #1f55ff; color: #ffffff',
  },
  debug: {
    enabled: true,
    level: 0,
  },
});

Vue.use(VueClipboard);

//SEO
Vue.use(VueAnalyticsPlugin);

// new Logger(`General`).info('Version on PORTAL: ' + Config.get('version'));

Vue.use(BusPlugin);
Vue.use(FeatureTogglePlugin);
Vue.use(VueAxios, axios);

Vue.use(vueScrollBehavior, { router: router });

Vue.use(ErrorPlugin);
Vue.use(BodyPlugin);

// Botstrap
Vue.use(DropdownPlugin);
Vue.use(NavbarPlugin);
Vue.use(AlertPlugin);
Vue.use(ModalPlugin);
Vue.use(CollapsePlugin);
Vue.use(SpinnerPlugin);
Vue.use(FormSelectPlugin);
Vue.use(VBVisiblePlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(ToastPlugin);
Vue.use(ImagePlugin);

Vue.use(ObserveVisibility);

/* Vue.use(SentryPlugin, {
  config: {
    dsn: 'https://d054c5bbcf4b423d872ec236ef2e896a@o1277054.ingest.sentry.io/6555170',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'int-verb.vidnt.com', 'market.live', /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
    environment: isMarketProduction ? 'production' : 'dev',
    release: process.env.VUE_APP_PROJECT_VERSION,
    ignoreUrls: [/store\.market\.live/],
  },
}); */

console.log('fastcast host', process.env.VUE_APP_FASTCAST_DOMAIN);

Config.loaded(() => {
  const measurementId = amsClient.get('conf.portal.global_site_tags.G');

  Vue.use(GoogleAnalyticsPlugin, {
    measurementId,
  });

  const dataDogEnvConfig =
    Config.get('env') === 'prod'
      ? {
          applicationId: 'f6fc09b1-72ef-474d-b057-abb8771a81b7',
          clientToken: 'pubfc198ee06409f1d50be5efdead49094b',
          sessionReplaySampleRate: 100,
          service: 'verb-prod-portal',
        }
      : {
          applicationId: '9f944474-f636-48e9-8980-23d7203ea738',
          clientToken: 'pub0992904f4ae243a377f6e1413a53865c',
          sessionReplaySampleRate: 20,
          service: 'verb-frontend',
        };

  datadogRum.init({
    site: 'datadoghq.com',
    env: Config.get('env'),
    version: process.env.VUE_APP_PROJECT_VERSION,
    sessionSampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      '/.*market.live.*/, /.*myshopify.com.*/, /.*vidnt.com.*/, /.*verb.live.*/',
    ],
    ...dataDogEnvConfig,
  });

  datadogRum.startSessionReplayRecording();
});

setTimeout(() => {
  Vue.use(ZendeskPlugin, {
    isMobile: device.isMobileSize() && device.isAnyMobile(),
    isProd: isProduction,
  });
}, 3000);

Vue.component('vue-headful', vueHeadful);

Vue.config.errorHandler = VueErrorHandler;
Vue.config.productionTip = !isProduction;
Vue.config.devtools = true;
Vue.config.performance = true;

Vue.prototype.$isPreviewMode = _isPreviewMode();
Vue.prototype.$location = location.origin + (isProduction ? '/portal' : '');
Vue.prototype.$floatingVodRef = null;
Vue.prototype.$floatingLiveRef = null;
Vue.prototype.$floatingWrapperRef = null;
Vue.prototype.$accountIframeRef = null;
Vue.prototype.$shopingCartIframeRef = null;
Vue.prototype.$productIframeRef = null;

amsClient.on('conf', async () => {
  const store = isProduction
    ? amsClient.get('conf.store')
    : {
        standalone: false,
        audience: 'private',
        slug: 'vladimir-sanbox-test-int',
      };

  Vue.prototype.$standaloneStoreName =
    (store?.standalone && store?.slug) || null;
  Vue.prototype.$isStandaloneStore = !!Vue.prototype.$standaloneStoreName;

  let isSystemOverloaded = false;
  try {
    /* const fastcastLimitsUrl = process.env.VUE_APP_FASTCAST_DOMAIN
      ? `https://${process.env.VUE_APP_FASTCAST_DOMAIN}`
      : '/fastcast'; */

    const { data } = await axios.get('/fastcast/limits');
    isSystemOverloaded = data.result.limit_is_reached;
  } catch (err) {
    console.log('err', err);
    isSystemOverloaded = true;
  }

  loadPortalApplication(isSystemOverloaded);
});

const loadPortalApplication = (isSystemOverloaded) => {
  if (isSystemOverloaded) {
    return new Vue({
      el: '#app',
      components: { OverLoadedSystemPage },
      template: '<OverLoadedSystemPage/>',
    });
  }

  const homeRoute = {
    path: '/',
    name: 'Home',
    component: Vue.prototype.$isStandaloneStore
      ? () => import('@/views/Page/Page')
      : () => import('@/views/MainHome/Home'),
    meta: {
      ...(Vue.prototype.$isStandaloneStore && {
        creatorPage: true,
        seoPage: true,
      }),
    },
  };

  const signInRoute = {
    path: '/sign-in',
    name: 'SignIn',
    component: Vue.prototype.$isStandaloneStore
      ? () => import('@/views/Page/Page')
      : () => import('@/views/MainHome/Home'),
  };

  router.addRoute(homeRoute);
  router.addRoute(signInRoute);

  new Vue({
    i18n,
    el: '#app',
    router,
    store,
    components: { App },
    created() {
      let oldVersion = localStore.get('version');

      if (!oldVersion) {
        // this.$stats.send('first_run', {});
        localStore.set('version', Config.get('version'));
      }

      if (oldVersion && oldVersion !== Config.get('version')) {
        localStore.set('version', Config.get('version'));
      }
    },
    mounted() {
      lifecycle.addEventListener('statechange', (event) => {
        if (event.newState === 'terminated') {
          this.$bus.$emit('beforeTerminated');
          // vapp.$stats.send('app_closed', {}, {
          //   beacon: true,
          //   fetch: false,
          // });
        }
      });
    },
    template: '<App/>',
  });
};

export const vapp = new Vue();
