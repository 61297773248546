<template>
  <vod-popup
    v-if="vodItem && !isClosed"
    :item="vodItem"
    product-popup-vod
    :class="{['shop_screen-miniature']: true, hide: vodPlaylist.length === 0}"
    @closeProductPopup="closeModal"
  />
</template>

<script>
  import { amsClient } from '@/service/ams';
  import VodPopup from '@/components/vod-popup/vod-popup';

  export default {
    name: 'ProductVodPopup',

    components: { VodPopup },

    props: {
      item: {
        type: Object,
        required: true,
      },
    },

    data: function () {
      return {
        vodPlaylist: [],
        currentVOD: null,
        eventIdentifier: Math.ceil(Math.random() * 10000000),
        isClosed: false,
        styles: {},
      };
    },

    computed: {
      timeIn() {
        return this.millisecondsToSeconds(this.currentVOD.time_in);
      },
      timeOut() {
        return this.millisecondsToSeconds(this.currentVOD.time_out);
      },
      vodItem() {
        if(this.currentVOD) {
          return {...this.currentVOD.data.head,
                  playerOptions: {
                    mode: "f",
                    autoplay: true,
                    controls: false,
                    showPlayButton: false,
                    startPosition: this.timeIn,
                    endPosition: this.timeOut,
                  },
                  eventIdentifier: this.eventIdentifier,
          };
        }
        return false;
      },
    },

    async created() {
      this.$bus.$on(this.eventIdentifier + '_playerEnded', this.event_getNextVod);
      this.$bus.$on('closeProductVideo', this.event_hideVideo);

      const response = await amsClient.callAms(`/shoprzapi/vod_playlist/${this.item.id}/?by=id`);
      this.vodPlaylist = await response.head.vod_playlist;

      if(this.vodPlaylist.length !== 0) {
        const playlistItems = [];

        for (let item of this.vodPlaylist) {
          const data = await amsClient.callAms(item.link);
          playlistItems.push({...item, data});
        }

        this.vodPlaylist = playlistItems.sort((current, next) => next.data.head.published_at.localeCompare(current.data.head.published_at));
        this.currentVOD = this.vodPlaylist[0];
      }
    },

    mounted() {},

    beforeDestroy() {
      this.$bus.$off(this.eventIdentifier + '_playerEnded', this.event_getNextVod);
      this.$bus.$off('closeProductVideo', this.event_hideVideo);
    },

    methods: {
      millisecondsToSeconds(time) {
        const seconds = Math.round(time / 1000);
        const milliseconds = time % 1000;
        return parseFloat(`${seconds}.${milliseconds}`);
      },
      event_getNextVod() {
        const index = this.vodPlaylist.indexOf(this.currentVOD)
        if (index === this.vodPlaylist.length - 1) {
          this.currentVOD = this.vodPlaylist[0];
        } else {
          this.currentVOD = this.vodPlaylist[index + 1];
        }
      },
      event_hideVideo() {
        this.isClosed = true;
      },
      closeModal() {
        this.$emit('closeProductPopup');
      },
    },
  };
</script>

<style scoped>
  .hide {
    display: none;
  }
</style>