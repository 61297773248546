<template>
  <div class="over-loaded-system-page" id="over-loaded-system-page">
    <div
      class="over-loaded-system-page--header"
      @click="onClickLogo"
    >
      <img :src="require('src/assets/logo/logo-market-footer.png')"/>
    </div>
    <div class="over-loaded-system-page--message">
      <div>
        <div class="over-loaded-system-page--message--main">
          We’re currently experiencing a high volume of requests.
        </div>
        <div class="over-loaded-system-page--message--main--secondary">
          Please wait a few minutes and try again. Thank you for your patience.
        </div>
      </div>
    </div>
    <div class="over-loaded-system-page--footer"/>
  </div>
</template>

<script>
  export default {
    name: 'OverLoadedSystemPage',
    props: {},

    data() {
      return {};
    },

    computed: {},

    methods: {
      onClickLogo() {
        location.reload();
      },
    },

  };
</script>

<style lang="scss" scoped>
  .over-loaded-system-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--header {
      padding-top: 55px;
      img {
        cursor: pointer;
      }
    }

    &--header, &--footer {
      flex: 1;
    }

    &--message {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex: 1;
      &--main {
        font-size: 24px;
        font-weight: 600;
      }
      &--secondary {
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
</style>
