import StockStoreObject from './stock.store';
import StoreWrapperService from 'src/store/store-wrapper.js';
import { amsClient } from '@/service/ams';

export let StockStore = new StoreWrapperService({
  name: 'stock',
  localStore: StockStoreObject,
  options: {
    mutations: true,
    getters: true,
    actions: true,
  },
  ignore: {
    mutations: [],
    getters: [],
    actions: [],
  },
  init: ({ store, name }) => {
    amsClient.on('auth_state_change', (event, data) => {
      setTimeout(() => {
        if (data.type === 'logged') {
          // store.dispatch(`${name}/onSync`, data);
        } else if (data.type === 'logout') {
          store.dispatch(`${name}/clean`, data);
        }
      });
    });
    amsClient.on('stock', (event, data) => {
      setTimeout(() => {
        store.dispatch(`${name}/onSync`, data);
      });
    });

    setTimeout(() => {
      store.dispatch(`${name}/onSync`, {});
    });
  },
});

export default StockStore;
