import { amsClient } from 'src/service/ams';

const state = () => ({
  playTemplate: null,
  chachedPreviewMedia: {},
});

const getters = {
  getPlayTemplate: state => {
    return state.playTemplate
  },
  chachedPreviewMedia: state => {
    return state.chachedPreviewMedia;
  },
}

const actions = {
  addPreviewMediaItem({ commit }, { id, link, isVertical }) {
    commit('setItem', { id, link, isVertical });
  },
  async setPlayTemplate({ state, commit }) {
    if (!state.playTemplate) {
      const template = await amsClient.callAms(`/playtemplate/`);

      commit('setTemplate', template);
    }
  },
}

const mutations = {
  setTemplate(state, value) {
    state.playTemplate = value;
  },
  setItem(state, { id, link, isVertical }) {
    state.chachedPreviewMedia = {
      ...state.chachedPreviewMedia,
      [id]: { link, isVertical },
    };
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};