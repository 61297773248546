import scriptManager from '@/service/scriptManager';

const install = function (Vue, options) {
  const script = scriptManager.addScript(
    'ze-snippet',
    'https://static.zdassets.com/ekr/snippet.js?key=9dc8b017-3a81-4773-bcca-33ddee8d8fd1',
    true,
    true,
  );

  script.onload = () => {
    if (options.isMobile) {
      zE('webWidget', 'hide');

      zE('webWidget:on', 'close', function () {
        zE('webWidget', 'hide');
      });
    }
  };
};

export default { install };